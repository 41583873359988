import React from 'react';
import type { SectionProps } from './types';

const CargoSection = ({ register, errors }: SectionProps) => {
  return (
    <div>
      <h3 className="text-lg font-medium text-gray-900 mb-4">Cargo Information</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Commodity <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            {...register('commodity')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            placeholder="Enter commodity"
          />
          {errors.commodity && (
            <p className="mt-1 text-sm text-red-600">{errors.commodity.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            HS Code <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            {...register('hsCode')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            placeholder="Enter HS code"
          />
          {errors.hsCode && (
            <p className="mt-1 text-sm text-red-600">{errors.hsCode.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Weight <span className="text-red-500">*</span>
          </label>
          <div className="flex space-x-2">
            <input
              type="number"
              step="0.01"
              {...register('weight', { valueAsNumber: true })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="Enter weight"
            />
            <select
              {...register('weightUnit')}
              className="mt-1 block w-24 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="KG">KG</option>
              <option value="LB">LB</option>
            </select>
          </div>
          {errors.weight && (
            <p className="mt-1 text-sm text-red-600">{errors.weight.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Volume <span className="text-red-500">*</span>
          </label>
          <div className="flex space-x-2">
            <input
              type="number"
              step="0.01"
              {...register('volume', { valueAsNumber: true })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="Enter volume"
            />
            <select
              {...register('volumeUnit')}
              className="mt-1 block w-24 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="M3">M³</option>
              <option value="FT3">FT³</option>
            </select>
          </div>
          {errors.volume && (
            <p className="mt-1 text-sm text-red-600">{errors.volume.message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CargoSection;