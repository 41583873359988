import React, { useState, useEffect } from 'react';
import { ArrowLeft, AlertCircle, Loader2 } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import { useShipmentStore } from '../stores/shipmentStore';
import { useAuthStore } from '../stores/authStore';
import { ShipmentStatus } from '../types/shipment';
import StatusBadge from './StatusBadge';
import ShipmentDocuments from './ShipmentDocuments';
import LoadingDateAlert from './admin/LoadingDateAlert';
import LoadingDateConfirmation from './admin/LoadingDateConfirmation';
import PortEntryForm from './admin/PortEntryForm';
import DepartureConfirmation from './admin/DepartureConfirmation';
import BookingInfoBanner from './shared/BookingInfoBanner';
import NextStepAlert from './shared/NextStepAlert';

const AdminShipmentDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { getShipment, updateShipment, isOffline, retryConnection } = useShipmentStore();
  const [shipment, setShipment] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showLoadingDateConfirmation, setShowLoadingDateConfirmation] = useState(false);
  const [showPortEntryForm, setShowPortEntryForm] = useState(false);
  const [showDepartureForm, setShowDepartureForm] = useState(false);

  useEffect(() => {
    const loadShipment = async () => {
      if (!id) {
        setError('Invalid shipment ID');
        setIsLoading(false);
        return;
      }
      
      try {
        setIsLoading(true);
        setError(null);
        const data = await getShipment(id);
        if (data) {
          setShipment(data);
        } else {
          setError('Shipment not found');
        }
      } catch (err: any) {
        console.error('Error loading shipment:', err);
        setError(err.message || 'Failed to load shipment details');
      } finally {
        setIsLoading(false);
      }
    };

    loadShipment();
  }, [id, getShipment]);

  const handleRetry = async () => {
    if (isOffline) {
      await retryConnection();
    }
    if (id) {
      setIsLoading(true);
      setError(null);
      try {
        const data = await getShipment(id);
        if (data) {
          setShipment(data);
        } else {
          setError('Shipment not found');
        }
      } catch (err: any) {
        setError(err.message || 'Failed to load shipment details');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDocumentUpdate = async (type: string, url: string) => {
    if (!shipment || !id) return;

    try {
      const updatedDocs = {
        ...shipment.documents,
        [type]: {
          url,
          uploadDate: new Date().toISOString(),
          fileName: `${type}_${shipment.referenceNumber}.pdf`
        }
      };

      await updateShipment(id, {
        ...shipment,
        documents: updatedDocs
      });

      const updatedShipment = await getShipment(id);
      if (updatedShipment) {
        setShipment(updatedShipment);
      }
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-8 w-8 text-blue-600 animate-spin" />
      </div>
    );
  }

  if (error || !shipment) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex flex-col items-center justify-center space-y-4">
          <AlertCircle className="h-12 w-12 text-red-500" />
          <div className="text-center">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Error Loading Shipment</h3>
            <p className="text-gray-600 mb-4">{error || 'Unable to load shipment details'}</p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => navigate('/shipments')}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
              >
                Back to Shipments
              </button>
              <button
                onClick={handleRetry}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700"
              >
                Retry
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <button
              onClick={() => navigate('/shipments')}
              className="mr-4 p-2 hover:bg-gray-100 rounded-full"
            >
              <ArrowLeft className="h-5 w-5" />
            </button>
            <div>
              <h2 className="text-2xl font-bold text-gray-900">Shipment Details</h2>
              <p className="text-sm text-gray-500">Reference: {shipment.referenceNumber}</p>
            </div>
          </div>
          <StatusBadge status={shipment.status} />
        </div>

        {/* Next Step Alert */}
        {user && shipment && (
          <NextStepAlert 
            status={shipment.status} 
            userRole={user.role}
          />
        )}

        {/* Loading Date Alert */}
        <LoadingDateAlert 
          shipment={shipment}
          onConfirm={() => setShowLoadingDateConfirmation(true)}
        />

        {/* Booking Info Banner */}
        <BookingInfoBanner
          bookingNumber={shipment.bookingNumber}
          mblNumber={shipment.mblNumber}
          vessel={shipment.vessel}
          etd={shipment.etd}
          eta={shipment.eta}
          portOfLoading={shipment.shippingDetails?.portOfLoading}
          portOfDischarge={shipment.shippingDetails?.portOfDischarge}
          containerType={shipment.shipmentInfo?.containerType}
          containerQuantity={shipment.shipmentInfo?.containerQuantity}
          shippingLine={shipment.shippingDetails?.shippingLine}
        />
      </div>

      {/* Documents Section */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-gray-900">Documents</h3>
          {shipment.status === ShipmentStatus.ON_WAY_TO_PORT && (
            <button
              onClick={() => setShowPortEntryForm(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
            >
              Set Port Entry
            </button>
          )}
          {shipment.status === ShipmentStatus.WAITING_FOR_SAILING && (
            <button
              onClick={() => setShowDepartureForm(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
            >
              Confirm Departure
            </button>
          )}
        </div>

        <ShipmentDocuments 
          shipmentId={shipment.id}
          documents={shipment.documents}
          onDocumentUpdate={handleDocumentUpdate}
        />
      </div>

      {/* Loading Date Confirmation Modal */}
      {showLoadingDateConfirmation && (
        <LoadingDateConfirmation
          shipment={shipment}
          onClose={() => setShowLoadingDateConfirmation(false)}
          onSuccess={async () => {
            setShowLoadingDateConfirmation(false);
            if (id) {
              const updatedShipment = await getShipment(id);
              if (updatedShipment) {
                setShipment(updatedShipment);
              }
            }
          }}
        />
      )}

      {/* Port Entry Modal */}
      {showPortEntryForm && (
        <PortEntryForm
          shipment={shipment}
          onClose={() => setShowPortEntryForm(false)}
          onSuccess={async () => {
            setShowPortEntryForm(false);
            if (id) {
              const updatedShipment = await getShipment(id);
              if (updatedShipment) {
                setShipment(updatedShipment);
              }
            }
          }}
        />
      )}

      {/* Departure Confirmation Modal */}
      {showDepartureForm && (
        <DepartureConfirmation
          shipment={shipment}
          onClose={() => setShowDepartureForm(false)}
          onSuccess={async () => {
            setShowDepartureForm(false);
            if (id) {
              const updatedShipment = await getShipment(id);
              if (updatedShipment) {
                setShipment(updatedShipment);
              }
            }
          }}
        />
      )}
    </div>
  );
};

export default AdminShipmentDetails;