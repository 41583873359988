import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, FileText, X, AlertCircle, Info, Save, Loader2 } from 'lucide-react';

interface PDFUploaderProps {
  pdfFile: File | null;
  onFileChange: (file: File | null) => void;
  error: string | null;
  errorDetails?: string;
  isSubmitting?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const PDFUploader = ({ 
  pdfFile, 
  onFileChange, 
  error, 
  errorDetails,
  isSubmitting,
  onSubmit,
  onCancel
}: PDFUploaderProps) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      onFileChange(file);
    }
  }, [onFileChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf']
    },
    maxFiles: 1,
    maxSize: 5 * 1024 * 1024, // 5MB
    onDropRejected: (fileRejections) => {
      const rejection = fileRejections[0];
      if (rejection.errors[0].code === 'file-too-large') {
        onFileChange(null);
      } else if (rejection.errors[0].code === 'file-invalid-type') {
        onFileChange(null);
      }
    }
  });

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (pdfFile && !isSubmitting) {
      onSubmit();
    }
  };

  return (
    <div className="mt-6 space-y-4">
      <div className="bg-blue-50 border-l-4 border-blue-400 p-4">
        <div className="flex">
          <Info className="h-5 w-5 text-blue-400 mr-2" />
          <div>
            <p className="text-sm font-medium text-blue-700">PDF Requirements</p>
            <ul className="mt-1 text-sm text-blue-600 list-disc list-inside">
              <li>Must be a valid SLI document</li>
              <li>Maximum file size: 5MB</li>
              <li>Must contain required sections: Order Information, Ship-to Party, Cargo Details</li>
              <li>Text must be selectable (not scanned images)</li>
            </ul>
          </div>
        </div>
      </div>

      <div
        {...getRootProps()}
        className={`border-2 border-dashed rounded-lg p-8 text-center transition-colors ${
          isDragActive 
            ? 'border-blue-500 bg-blue-50' 
            : error 
              ? 'border-red-300 bg-red-50'
              : 'border-gray-300 hover:border-blue-400'
        }`}
      >
        <input {...getInputProps()} />
        <div className="flex flex-col items-center">
          <Upload className={`h-12 w-12 mb-4 ${
            isDragActive ? 'text-blue-500' : error ? 'text-red-400' : 'text-gray-400'
          }`} />
          {isDragActive ? (
            <p className="text-blue-600">Drop the PDF here</p>
          ) : (
            <>
              <p className="text-gray-600">
                Click to upload or drag and drop your SLI PDF
              </p>
              <p className="text-sm text-gray-500 mt-2">PDF up to 5MB</p>
            </>
          )}
        </div>
      </div>

      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
            <div>
              <p className="text-sm font-medium text-red-800">{error}</p>
              {errorDetails && (
                <p className="mt-1 text-sm text-red-700">{errorDetails}</p>
              )}
            </div>
          </div>
        </div>
      )}

      {pdfFile && !error && (
        <div className="p-4 bg-gray-50 rounded-lg flex items-center justify-between">
          <div className="flex items-center">
            <FileText className="h-5 w-5 text-gray-500 mr-2" />
            <span className="text-sm text-gray-700">{pdfFile.name}</span>
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onFileChange(null);
            }}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
      )}

      <div className="flex justify-end space-x-4 mt-6">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={handleSubmit}
          disabled={!pdfFile || isSubmitting}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
        >
          {isSubmitting ? (
            <>
              <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
              Submitting...
            </>
          ) : (
            <>
              <Save className="h-5 w-5 mr-2" />
              Submit SLI
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default PDFUploader;