import React from 'react';
import { ShipmentStatus } from '../types/shipment';

interface StatusBadgeProps {
  status: string;
}

const StatusBadge = ({ status }: StatusBadgeProps) => {
  const getStatusStyles = () => {
    switch (status) {
      case ShipmentStatus.BOOKING_REQUESTED:
        return 'bg-gradient-to-r from-yellow-100 to-amber-100 text-amber-800 border border-amber-200';
      case ShipmentStatus.BOOKING_CONFIRMED:
        return 'bg-gradient-to-r from-green-100 to-emerald-100 text-emerald-800 border border-emerald-200';
      case ShipmentStatus.WAITING_FOR_SLI:
        return 'bg-gradient-to-r from-orange-100 to-amber-100 text-amber-800 border border-amber-200';
      case ShipmentStatus.WAITING_FOR_LOADING:
        return 'bg-gradient-to-r from-cyan-100 to-sky-100 text-sky-800 border border-sky-200';
      case ShipmentStatus.LOADING_CONFIRMED:
        return 'bg-gradient-to-r from-blue-100 to-indigo-100 text-indigo-800 border border-indigo-200';
      case ShipmentStatus.ON_WAY_TO_PORT:
        return 'bg-gradient-to-r from-indigo-100 to-violet-100 text-violet-800 border border-violet-200';
      case ShipmentStatus.WAITING_FOR_SAILING:
        return 'bg-gradient-to-r from-violet-100 to-purple-100 text-purple-800 border border-purple-200';
      case ShipmentStatus.DEPARTED:
        return 'bg-gradient-to-r from-purple-100 to-fuchsia-100 text-fuchsia-800 border border-fuchsia-200';
      case ShipmentStatus.DELIVERED:
        return 'bg-gradient-to-r from-green-100 to-emerald-100 text-emerald-800 border border-emerald-200';
      default:
        return 'bg-gradient-to-r from-gray-100 to-slate-100 text-slate-800 border border-slate-200';
    }
  };

  const getStatusText = () => {
    switch (status) {
      case ShipmentStatus.BOOKING_REQUESTED:
        return 'Booking Requested';
      case ShipmentStatus.BOOKING_CONFIRMED:
        return 'Booking Confirmed';
      case ShipmentStatus.WAITING_FOR_SLI:
        return 'Waiting for SLI';
      case ShipmentStatus.WAITING_FOR_LOADING:
        return 'Waiting for Loading';
      case ShipmentStatus.LOADING_CONFIRMED:
        return 'Loading Confirmed';
      case ShipmentStatus.ON_WAY_TO_PORT:
        return 'On Way to Port';
      case ShipmentStatus.WAITING_FOR_SAILING:
        return 'Waiting for Sailing';
      case ShipmentStatus.DEPARTED:
        return 'Departed';
      case ShipmentStatus.DELIVERED:
        return 'Delivered';
      default:
        return status || 'Unknown';
    }
  };

  return (
    <span className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium shadow-sm ${getStatusStyles()}`}>
      {getStatusText()}
    </span>
  );
};

export default StatusBadge;