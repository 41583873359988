import React from 'react';
import { Calendar, AlertCircle, Clock } from 'lucide-react';
import { format, parseISO } from 'date-fns';

interface LoadingDateAlertProps {
  shipment: any;
  onConfirm: () => void;
}

const LoadingDateAlert = ({ shipment, onConfirm }: LoadingDateAlertProps) => {
  if (!shipment.loadingDate || !shipment.loadingTime || shipment.loadingDateConfirmed) {
    return null;
  }

  const formatDate = (dateString: string) => {
    try {
      return format(parseISO(dateString), 'MMMM d, yyyy');
    } catch {
      return dateString;
    }
  };

  const formatTime = (timeString: string) => {
    try {
      const [hours, minutes] = timeString.split(':');
      return format(new Date().setHours(parseInt(hours), parseInt(minutes)), 'h:mm a');
    } catch {
      return timeString;
    }
  };

  return (
    <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
      <div className="flex">
        <AlertCircle className="h-5 w-5 text-yellow-400 mt-0.5 mr-3" />
        <div className="flex-1">
          <h3 className="text-sm font-medium text-yellow-800">Loading Date Requires Confirmation</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>Customer has set the following loading date and time:</p>
            <div className="mt-2 grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <Calendar className="h-4 w-4 mr-2" />
                <span>Date: {formatDate(shipment.loadingDate)}</span>
              </div>
              <div className="flex items-center">
                <Clock className="h-4 w-4 mr-2" />
                <span>Time: {formatTime(shipment.loadingTime)}</span>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <button
              onClick={onConfirm}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700"
            >
              Review & Confirm Loading Date
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingDateAlert;