import React from 'react';
import { AlertCircle, ArrowRight } from 'lucide-react';
import { ShipmentStatus } from '../../types/shipment';

interface NextStepAlertProps {
  status: string;
  userRole: string;
}

const NextStepAlert = ({ status, userRole }: NextStepAlertProps) => {
  const getNextStep = () => {
    switch (status) {
      case ShipmentStatus.BOOKING_REQUESTED:
        return {
          forRole: 'admin',
          message: 'Review and confirm the booking request'
        };
      
      case ShipmentStatus.BOOKING_CONFIRMED:
        return {
          forRole: 'admin',
          message: 'Enter booking details including vessel and container information'
        };

      case ShipmentStatus.WAITING_FOR_SLI:
        return {
          forRole: 'customer',
          message: "Submit Shipper's Letter of Instruction (SLI)"
        };

      case ShipmentStatus.DOCUMENTS_PENDING:
        return {
          forRole: 'admin',
          message: 'Review uploaded documents and confirm loading date'
        };

      case ShipmentStatus.DOCUMENTS_RECEIVED:
        return {
          forRole: 'admin',
          message: 'Verify documents and confirm loading details'
        };

      case ShipmentStatus.LOADING_CONFIRMED:
        return {
          forRole: 'trucker',
          message: 'Prepare for pickup and port entry'
        };

      default:
        return null;
    }
  };

  const nextStep = getNextStep();
  
  if (!nextStep || nextStep.forRole !== userRole) {
    return null;
  }

  return (
    <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mb-6">
      <div className="flex">
        <AlertCircle className="h-5 w-5 text-blue-400 mt-0.5 mr-3" />
        <div>
          <h3 className="text-sm font-medium text-blue-800">Next Step Required</h3>
          <div className="mt-2 flex items-center text-sm text-blue-700">
            <ArrowRight className="h-4 w-4 mr-2" />
            {nextStep.message}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NextStepAlert;