import React, { useState } from 'react';
import { collection, addDoc, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../config/firebase';
import { Loader2, CheckCircle, XCircle } from 'lucide-react';

const FirebaseTest = () => {
  const [testMessage, setTestMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [lastMessage, setLastMessage] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus('idle');

    try {
      // Try to add a document
      const docRef = await addDoc(collection(db, 'test_messages'), {
        message: testMessage,
        timestamp: Timestamp.now()
      });

      // Try to read documents
      const querySnapshot = await getDocs(collection(db, 'test_messages'));
      const messages = querySnapshot.docs.map(doc => doc.data().message);
      setLastMessage(messages[messages.length - 1]);
      
      setStatus('success');
      setTestMessage('');
    } catch (error) {
      console.error('Firebase test error:', error);
      setStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-lg font-semibold mb-4">Firebase Connection Test</h2>
      
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Test Message
          </label>
          <input
            type="text"
            value={testMessage}
            onChange={(e) => setTestMessage(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            placeholder="Enter a test message"
          />
        </div>

        <button
          type="submit"
          disabled={isSubmitting || !testMessage}
          className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
        >
          {isSubmitting ? (
            <>
              <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
              Testing...
            </>
          ) : (
            'Test Connection'
          )}
        </button>
      </form>

      {/* Status Display */}
      {status !== 'idle' && (
        <div className={`mt-4 p-4 rounded-md ${
          status === 'success' ? 'bg-green-50' : 'bg-red-50'
        }`}>
          <div className="flex items-center">
            {status === 'success' ? (
              <>
                <CheckCircle className="h-5 w-5 text-green-400 mr-2" />
                <div>
                  <p className="text-sm font-medium text-green-800">
                    Connection successful!
                  </p>
                  {lastMessage && (
                    <p className="mt-1 text-sm text-green-700">
                      Last message: {lastMessage}
                    </p>
                  )}
                </div>
              </>
            ) : (
              <>
                <XCircle className="h-5 w-5 text-red-400 mr-2" />
                <p className="text-sm font-medium text-red-800">
                  Connection failed. Check console for details.
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FirebaseTest;