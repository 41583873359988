import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Save, Loader2, X, AlertCircle } from 'lucide-react';
import { useShipmentStore } from '../../stores/shipmentStore';
import { ShipmentStatus } from '../../types/shipment';
import BookingInfoBanner from '../shared/BookingInfoBanner';

const portEntrySchema = z.object({
  entryToPortDate: z.string().min(1, 'Port entry date is required'),
  entryTime: z.string().min(1, 'Entry time is required'),
  notes: z.string().optional()
});

type PortEntryData = z.infer<typeof portEntrySchema>;

interface PortEntryFormProps {
  shipment: any;
  onClose: () => void;
  onSuccess?: () => void;
}

const PortEntryForm = ({ shipment, onClose, onSuccess }: PortEntryFormProps) => {
  const { updateShipment } = useShipmentStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<PortEntryData>({
    resolver: zodResolver(portEntrySchema)
  });

  const onSubmit = async (data: PortEntryData) => {
    setIsSubmitting(true);
    setError(null);

    try {
      await updateShipment(shipment.id, {
        ...shipment,
        entryToPortDate: data.entryToPortDate,
        entryTime: data.entryTime,
        portEntryNotes: data.notes,
        status: ShipmentStatus.WAITING_FOR_SAILING, // Updated status
        portEntryConfirmedAt: new Date().toISOString()
      });

      onSuccess?.();
      onClose();
    } catch (error: any) {
      console.error('Error setting port entry:', error);
      setError(error.message || 'Failed to set port entry');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full">
        <div className="p-6 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-xl font-bold text-gray-900">Set Port Entry</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="p-6">
          <BookingInfoBanner
            bookingNumber={shipment.bookingNumber}
            mblNumber={shipment.mblNumber}
            vessel={shipment.vessel}
            etd={shipment.etd}
            eta={shipment.eta}
            portOfLoading={shipment.shippingDetails?.portOfLoading}
            portOfDischarge={shipment.shippingDetails?.portOfDischarge}
            containerType={shipment.shipmentInfo?.containerType}
            containerQuantity={shipment.shipmentInfo?.containerQuantity}
            shippingLine={shipment.shippingDetails?.shippingLine}
          />

          <form onSubmit={handleSubmit(onSubmit)} className="mt-6 space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Port Entry Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  {...register('entryToPortDate')}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
                {errors.entryToPortDate && (
                  <p className="mt-1 text-sm text-red-600">{errors.entryToPortDate.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Entry Time <span className="text-red-500">*</span>
                </label>
                <input
                  type="time"
                  {...register('entryTime')}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
                {errors.entryTime && (
                  <p className="mt-1 text-sm text-red-600">{errors.entryTime.message}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Notes</label>
              <textarea
                {...register('notes')}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Add any notes about the port entry..."
              />
            </div>

            {error && (
              <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
                  <p className="text-sm text-red-600">{error}</p>
                </div>
              </div>
            )}

            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                    Saving...
                  </>
                ) : (
                  <>
                    <Save className="h-5 w-5 mr-2" />
                    Confirm Port Entry
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PortEntryForm;