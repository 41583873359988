import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Save, Loader2, CheckCircle2 } from 'lucide-react';
import { useShipmentStore } from '../stores/shipmentStore';

const truckingSchema = z.object({
  truckingStatus: z.enum(['pending', 'in_transit', 'delivered']),
  driverName: z.string().min(1, 'Driver name is required'),
  driverPhone: z.string().min(1, 'Driver phone is required'),
  truckNumber: z.string().min(1, 'Truck number is required'),
  pickupDate: z.string().min(1, 'Pickup date is required'),
  actualPickupTime: z.string().optional(),
  actualDeliveryTime: z.string().optional(),
  notes: z.string().optional(),
});

type TruckingFormData = z.infer<typeof truckingSchema>;

interface TruckingFormProps {
  shipmentId: string;
  initialData: any;
  onSuccess?: () => void;
}

const TruckingForm = ({ shipmentId, initialData, onSuccess }: TruckingFormProps) => {
  const { updateShipment } = useShipmentStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<TruckingFormData>({
    resolver: zodResolver(truckingSchema),
    defaultValues: {
      truckingStatus: initialData?.truckingStatus || 'pending',
      driverName: initialData?.driverName || '',
      driverPhone: initialData?.driverPhone || '',
      truckNumber: initialData?.truckNumber || '',
      pickupDate: initialData?.pickupDate || '',
      actualPickupTime: initialData?.actualPickupTime || '',
      actualDeliveryTime: initialData?.actualDeliveryTime || '',
      notes: initialData?.notes || '',
    },
  });

  const onSubmit = async (data: TruckingFormData) => {
    setIsSubmitting(true);
    setError(null);
    setShowSuccess(false);

    try {
      await updateShipment(shipmentId, {
        ...initialData,
        ...data,
      });
      
      setShowSuccess(true);
      onSuccess?.();
      
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
    } catch (error) {
      console.error('Error updating shipment:', error);
      setError('Failed to update shipment. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      {showSuccess && (
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex items-center">
            <CheckCircle2 className="h-5 w-5 text-green-400 mr-2" />
            <p className="text-sm font-medium text-green-800">
              Changes saved successfully!
            </p>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Status</label>
          <select
            {...register('truckingStatus')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="pending">Pending Pickup</option>
            <option value="in_transit">In Transit</option>
            <option value="delivered">Delivered</option>
          </select>
          {errors.truckingStatus && (
            <p className="mt-1 text-sm text-red-600">{errors.truckingStatus.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Driver Name</label>
          <input
            type="text"
            {...register('driverName')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
          {errors.driverName && (
            <p className="mt-1 text-sm text-red-600">{errors.driverName.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Driver Phone</label>
          <input
            type="text"
            {...register('driverPhone')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
          {errors.driverPhone && (
            <p className="mt-1 text-sm text-red-600">{errors.driverPhone.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Truck Number</label>
          <input
            type="text"
            {...register('truckNumber')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
          {errors.truckNumber && (
            <p className="mt-1 text-sm text-red-600">{errors.truckNumber.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Pickup Date</label>
          <input
            type="date"
            {...register('pickupDate')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
          {errors.pickupDate && (
            <p className="mt-1 text-sm text-red-600">{errors.pickupDate.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Actual Pickup Time</label>
          <input
            type="datetime-local"
            {...register('actualPickupTime')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Actual Delivery Time</label>
          <input
            type="datetime-local"
            {...register('actualDeliveryTime')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Notes</label>
          <textarea
            {...register('notes')}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            placeholder="Add any special instructions or notes..."
          />
        </div>
      </div>

      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <p className="text-sm text-red-600">{error}</p>
        </div>
      )}

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={isSubmitting}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
        >
          {isSubmitting ? (
            <>
              <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
              Updating...
            </>
          ) : (
            <>
              <Save className="h-5 w-5 mr-2" />
              Save Changes
            </>
          )}
        </button>
      </div>
    </form>
  );
};

export default TruckingForm;