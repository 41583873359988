import React from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { PRESET_SHIPPERS } from '../../data/shippers';
import type { BookingFormData } from './types';

interface ShipperSectionProps {
  register: UseFormRegister<BookingFormData>;
  setValue: UseFormSetValue<BookingFormData>;
  errors: any;
  selectedShipper: string;
  onShipperSelect: (id: string) => void;
}

const ShipperSection = ({ 
  register, 
  errors, 
  selectedShipper,
  onShipperSelect 
}: ShipperSectionProps) => {
  const renderShipperInfo = () => {
    const shipper = PRESET_SHIPPERS.find(s => s.id === selectedShipper);
    if (!shipper) return null;

    return (
      <div className="mt-4 bg-gray-50 rounded-lg p-4">
        <h4 className="text-sm font-medium text-gray-700 mb-2">Selected Shipper Details</h4>
        <div className="grid grid-cols-2 gap-4 text-sm">
          <div>
            <span className="font-medium">Company:</span> {shipper.name}
          </div>
          <div>
            <span className="font-medium">Contact:</span> {shipper.contactPerson}
          </div>
          <div>
            <span className="font-medium">Email:</span> {shipper.email}
          </div>
          <div>
            <span className="font-medium">Phone:</span> {shipper.phone}
          </div>
          <div>
            <span className="font-medium">Tax ID:</span> {shipper.taxId}
          </div>
          <div>
            <span className="font-medium">Country:</span> {shipper.country}
          </div>
          <div className="col-span-2">
            <span className="font-medium">Address:</span> {shipper.address}, {shipper.city}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h3 className="text-lg font-medium text-gray-900 mb-4">Shipper Information</h3>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">Select Shipper</label>
        <select
          value={selectedShipper}
          onChange={(e) => onShipperSelect(e.target.value)}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        >
          {PRESET_SHIPPERS.map(shipper => (
            <option key={shipper.id} value={shipper.id}>
              {shipper.name}
            </option>
          ))}
          <option value="new">Add New Shipper</option>
        </select>
      </div>

      {selectedShipper !== 'new' && renderShipperInfo()}

      {selectedShipper === 'new' && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Company Name</label>
            <input
              type="text"
              {...register('shipper.name')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.shipper?.name && (
              <p className="mt-1 text-sm text-red-600">{errors.shipper.name.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Contact Person</label>
            <input
              type="text"
              {...register('shipper.contactPerson')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              {...register('shipper.email')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Phone</label>
            <input
              type="text"
              {...register('shipper.phone')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Tax ID</label>
            <input
              type="text"
              {...register('shipper.taxId')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.shipper?.taxId && (
              <p className="mt-1 text-sm text-red-600">{errors.shipper.taxId.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Country</label>
            <input
              type="text"
              {...register('shipper.country')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.shipper?.country && (
              <p className="mt-1 text-sm text-red-600">{errors.shipper.country.message}</p>
            )}
          </div>

          <div className="col-span-2">
            <label className="block text-sm font-medium text-gray-700">Address</label>
            <input
              type="text"
              {...register('shipper.address')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.shipper?.address && (
              <p className="mt-1 text-sm text-red-600">{errors.shipper.address.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">City</label>
            <input
              type="text"
              {...register('shipper.city')}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.shipper?.city && (
              <p className="mt-1 text-sm text-red-600">{errors.shipper.city.message}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ShipperSection;