import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import type { BookingFormData } from './types';

interface AdditionalSectionProps {
  register: UseFormRegister<BookingFormData>;
  errors: any;
}

const AdditionalSection = ({ register, errors }: AdditionalSectionProps) => {
  return (
    <div>
      <h3 className="text-lg font-medium text-gray-900 mb-4">Additional Information</h3>
      
      <div className="space-y-6">
        {/* Special Requirements */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Special Requirements</label>
          <textarea
            {...register('specialRequirements')}
            rows={3}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            placeholder="Enter any special requirements or instructions..."
          />
        </div>
      </div>
    </div>
  );
};

export default AdditionalSection;