import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Save, Loader2, X, AlertCircle } from 'lucide-react';
import { useShipmentStore } from '../stores/shipmentStore';
import { ShipmentStatus } from '../types/shipment';
import { CHILE_PICKUP_LOCATIONS } from '../data/locations';

const bookingDetailsSchema = z.object({
  vessel: z.string().min(1, 'Vessel name is required'),
  bookingNumber: z.string().min(1, 'Booking number is required'),
  mblNumber: z.string().min(1, 'MBL number is required'),
  etd: z.string().min(1, 'ETD is required'),
  eta: z.string().min(1, 'ETA is required'),
  shippingLine: z.string().min(1, 'Shipping line is required'),
  pickupLocation: z.string().min(1, 'Empty container pickup location is required'),
  notes: z.string().optional()
});

type BookingDetailsData = z.infer<typeof bookingDetailsSchema>;

interface BookingDetailsFormProps {
  shipment: any;
  onClose: () => void;
  onSuccess?: () => void;
}

const BookingDetailsForm = ({ shipment, onClose, onSuccess }: BookingDetailsFormProps) => {
  const { updateShipment } = useShipmentStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<BookingDetailsData>({
    resolver: zodResolver(bookingDetailsSchema),
    defaultValues: {
      vessel: shipment.vessel || '',
      bookingNumber: shipment.bookingNumber || '',
      mblNumber: shipment.mblNumber || '',
      etd: shipment.etd || '',
      eta: shipment.eta || '',
      shippingLine: shipment.shippingLine || '',
      pickupLocation: shipment.pickupLocation || '',
      notes: shipment.notes || ''
    }
  });

  const onSubmit = async (data: BookingDetailsData) => {
    setIsSubmitting(true);
    setError(null);

    try {
      await updateShipment(shipment.id, {
        ...shipment,
        vessel: data.vessel,
        bookingNumber: data.bookingNumber,
        mblNumber: data.mblNumber,
        etd: data.etd,
        eta: data.eta,
        shippingLine: data.shippingLine,
        pickupLocation: data.pickupLocation,
        notes: data.notes,
        status: ShipmentStatus.WAITING_FOR_SLI,
        bookingDetailsSubmitted: true,
        bookingDetailsSubmittedAt: new Date().toISOString()
      });

      onSuccess?.();
      onClose();
    } catch (error: any) {
      console.error('Error submitting booking details:', error);
      setError(error.message || 'Failed to submit booking details');
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full">
        <div className="p-6 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-xl font-bold text-gray-900">Enter Booking Details</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Vessel Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                {...register('vessel')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              {errors.vessel && (
                <p className="mt-1 text-sm text-red-600">{errors.vessel.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Booking Number <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                {...register('bookingNumber')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              {errors.bookingNumber && (
                <p className="mt-1 text-sm text-red-600">{errors.bookingNumber.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                MBL Number <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                {...register('mblNumber')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              {errors.mblNumber && (
                <p className="mt-1 text-sm text-red-600">{errors.mblNumber.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                ETD <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                {...register('etd')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              {errors.etd && (
                <p className="mt-1 text-sm text-red-600">{errors.etd.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                ETA <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                {...register('eta')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              {errors.eta && (
                <p className="mt-1 text-sm text-red-600">{errors.eta.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Shipping Line <span className="text-red-500">*</span>
              </label>
              <select
                {...register('shippingLine')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">Select Shipping Line</option>
                <option value="MAERSK">Maersk</option>
                <option value="MSC">MSC</option>
                <option value="CMA CGM">CMA CGM</option>
                <option value="HAPAG-LLOYD">Hapag-Lloyd</option>
                <option value="ONE">ONE</option>
              </select>
              {errors.shippingLine && (
                <p className="mt-1 text-sm text-red-600">{errors.shippingLine.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Empty Container Pickup Location <span className="text-red-500">*</span>
              </label>
              <select
                {...register('pickupLocation')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">Select Pickup Location</option>
                {CHILE_PICKUP_LOCATIONS.map(location => (
                  <option key={location.code} value={location.code}>
                    {location.name}
                  </option>
                ))}
              </select>
              {errors.pickupLocation && (
                <p className="mt-1 text-sm text-red-600">{errors.pickupLocation.message}</p>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Notes</label>
            <textarea
              {...register('notes')}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="Add any notes about the booking..."
            />
          </div>

          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
                <p className="text-sm text-red-600">{error}</p>
              </div>
            </div>
          )}

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {isSubmitting ? (
                <>
                  <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                  Saving...
                </>
              ) : (
                <>
                  <Save className="h-5 w-5 mr-2" />
                  Submit Details
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookingDetailsForm;