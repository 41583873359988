export const CHILE_PICKUP_LOCATIONS = [
  { code: 'STGO', name: 'Santiago' },
  { code: 'SAI', name: 'San Antonio' },
  { code: 'VAP', name: 'Valparaíso' },
  { code: 'IQQ', name: 'Iquique' },
  { code: 'CNL', name: 'Coronel' },
  { code: 'LQN', name: 'Lirquén' },
  { code: 'SVM', name: 'San Vicente' },
  { code: 'ARI', name: 'Arica' },
  { code: 'ANT', name: 'Antofagasta' }
] as const;