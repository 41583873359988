import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Save, Loader2, X, AlertCircle, Calendar, CheckCircle2, FileText } from 'lucide-react';
import { useShipmentStore } from '../../stores/shipmentStore';
import { ShipmentStatus } from '../../types/shipment';
import BookingInfoBanner from '../shared/BookingInfoBanner';

const loadingDateConfirmationSchema = z.object({
  confirmedLoadingDate: z.string().min(1, 'Loading date confirmation is required'),
  confirmedLoadingTime: z.string().min(1, 'Loading time confirmation is required'),
  confirmationNotes: z.string().optional(),
  mandatoVerified: z.boolean(),
  proformaVerified: z.boolean()
});

type LoadingDateConfirmationData = z.infer<typeof loadingDateConfirmationSchema>;

interface LoadingDateConfirmationProps {
  shipment: any;
  onClose: () => void;
  onSuccess?: () => void;
}

const LoadingDateConfirmation = ({ shipment, onClose, onSuccess }: LoadingDateConfirmationProps) => {
  const { updateShipment } = useShipmentStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<LoadingDateConfirmationData>({
    resolver: zodResolver(loadingDateConfirmationSchema),
    defaultValues: {
      confirmedLoadingDate: shipment.plannedLoadingDate || '',
      confirmedLoadingTime: shipment.plannedLoadingTime || '',
      confirmationNotes: '',
      mandatoVerified: false,
      proformaVerified: false
    }
  });

  const mandatoVerified = watch('mandatoVerified');
  const proformaVerified = watch('proformaVerified');
  const allDocsVerified = mandatoVerified && proformaVerified;

  const onSubmit = async (data: LoadingDateConfirmationData) => {
    if (!allDocsVerified) {
      setError('Please verify both MANDATO and PROFORMA Invoice documents before confirming');
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      await updateShipment(shipment.id, {
        ...shipment,
        loadingDateConfirmed: true,
        loadingDateConfirmedAt: new Date().toISOString(),
        confirmedLoadingDate: data.confirmedLoadingDate,
        confirmedLoadingTime: data.confirmedLoadingTime,
        confirmationNotes: data.confirmationNotes,
        documentsVerified: true,
        status: ShipmentStatus.LOADING_CONFIRMED,
        adminConfirmation: {
          confirmedAt: new Date().toISOString(),
          notes: data.confirmationNotes,
          mandatoVerified: true,
          proformaVerified: true
        }
      });

      onSuccess?.();
      onClose();
    } catch (error: any) {
      console.error('Error confirming loading date:', error);
      setError(error.message || 'Failed to confirm loading date');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full">
        <div className="p-6 border-b border-gray-200 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Calendar className="h-6 w-6 text-blue-600" />
            <h2 className="text-xl font-bold text-gray-900">Confirm Loading Date & Documents</h2>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="p-6">
          <BookingInfoBanner
            bookingNumber={shipment.bookingNumber}
            mblNumber={shipment.mblNumber}
            vessel={shipment.vessel}
            etd={shipment.etd}
            eta={shipment.eta}
            portOfLoading={shipment.shippingDetails?.portOfLoading}
            portOfDischarge={shipment.shippingDetails?.portOfDischarge}
            containerType={shipment.shipmentInfo?.containerType}
            containerQuantity={shipment.shipmentInfo?.containerQuantity}
            shippingLine={shipment.shippingDetails?.shippingLine}
          />

          <form onSubmit={handleSubmit(onSubmit)} className="mt-6 space-y-6">
            {/* Document Verification */}
            <div className="bg-gray-50 rounded-lg p-4">
              <h3 className="text-sm font-medium text-gray-900 mb-4">Document Verification</h3>
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <FileText className="h-5 w-5 text-gray-400" />
                    <span className="text-sm text-gray-700">MANDATO</span>
                  </div>
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      {...register('mandatoVerified')}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <span className="text-sm text-gray-700">Verified</span>
                  </label>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <FileText className="h-5 w-5 text-gray-400" />
                    <span className="text-sm text-gray-700">PROFORMA Invoice</span>
                  </div>
                  <label className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      {...register('proformaVerified')}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <span className="text-sm text-gray-700">Verified</span>
                  </label>
                </div>
              </div>
            </div>

            {/* Loading Date Confirmation */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Confirm Loading Date <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  {...register('confirmedLoadingDate')}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
                {errors.confirmedLoadingDate && (
                  <p className="mt-1 text-sm text-red-600">{errors.confirmedLoadingDate.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Confirm Loading Time <span className="text-red-500">*</span>
                </label>
                <input
                  type="time"
                  {...register('confirmedLoadingTime')}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
                {errors.confirmedLoadingTime && (
                  <p className="mt-1 text-sm text-red-600">{errors.confirmedLoadingTime.message}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Confirmation Notes
              </label>
              <textarea
                {...register('confirmationNotes')}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Add any notes about the loading confirmation..."
              />
            </div>

            {!allDocsVerified && (
              <div className="rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 text-yellow-400 mr-2" />
                  <p className="text-sm text-yellow-700">
                    Please verify both MANDATO and PROFORMA Invoice documents before confirming
                  </p>
                </div>
              </div>
            )}

            {error && (
              <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
                  <p className="text-sm text-red-600">{error}</p>
                </div>
              </div>
            )}

            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting || !allDocsVerified}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                    Confirming...
                  </>
                ) : (
                  <>
                    <CheckCircle2 className="h-5 w-5 mr-2" />
                    Confirm Loading Date
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoadingDateConfirmation;