import React, { useState, useEffect } from 'react';
import { ArrowLeft } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import { useShipmentStore } from '../stores/shipmentStore';
import { useAuthStore } from '../stores/authStore';
import StatusBadge from './StatusBadge';
import CustomerLoadingConfirmation from './CustomerLoadingConfirmation';
import NextStepAlert from './shared/NextStepAlert';
import BookingInfoBanner from './shared/BookingInfoBanner';

interface InfoItemProps {
  label: string;
  value: string | undefined;
}

const InfoItem = ({ label, value }: InfoItemProps) => (
  <div>
    <h4 className="text-sm font-medium text-gray-500">{label}</h4>
    <p className="mt-1 text-sm text-gray-900">{value || 'Not specified'}</p>
  </div>
);

const ShipmentDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { getShipment } = useShipmentStore();
  const [shipment, setShipment] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadShipment = async () => {
      if (!id) return;
      
      try {
        setIsLoading(true);
        const data = await getShipment(id);
        if (data) {
          setShipment(data);
        } else {
          setError('Shipment not found');
        }
      } catch (err) {
        setError('Error loading shipment');
        console.error('Error loading shipment:', err);
      } finally {
        setIsLoading(false);
      }
    };

    loadShipment();
  }, [id, getShipment]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error || !shipment) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="text-center text-red-600">
          {error || 'Shipment not found'}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <button
              onClick={() => navigate('/customer/shipments')}
              className="mr-4 p-2 hover:bg-gray-100 rounded-full"
            >
              <ArrowLeft className="h-5 w-5" />
            </button>
            <div>
              <h2 className="text-2xl font-bold text-gray-900">Shipment Details</h2>
              <p className="text-sm text-gray-500">Reference #: {shipment.referenceNumber}</p>
            </div>
          </div>
          <StatusBadge status={shipment.status} />
        </div>

        {/* Next Step Alert */}
        {user && shipment && (
          <NextStepAlert 
            status={shipment.status} 
            userRole={user.role}
          />
        )}

        {/* Loading Date Confirmation Banner */}
        <CustomerLoadingConfirmation shipment={shipment} />

        {/* Booking Info Banner - Only show if loading date is not confirmed */}
        {!shipment.loadingDateConfirmed && (
          <BookingInfoBanner
            bookingNumber={shipment.bookingNumber}
            mblNumber={shipment.mblNumber}
            vessel={shipment.vessel}
            etd={shipment.etd}
            eta={shipment.eta}
            portOfLoading={shipment.shippingDetails?.portOfLoading}
            portOfDischarge={shipment.shippingDetails?.portOfDischarge}
            containerType={shipment.shipmentInfo?.containerType}
            containerQuantity={shipment.shipmentInfo?.containerQuantity}
            shippingLine={shipment.shippingDetails?.shippingLine}
          />
        )}
      </div>

      {/* Basic Information */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Basic Information</h3>
        <div className="grid grid-cols-2 gap-4">
          <InfoItem label="Reference #" value={shipment.referenceNumber} />
          <InfoItem label="PO Number" value={shipment.shipmentInfo?.customerPO} />
          <InfoItem label="Contract Reference" value={shipment.shipmentInfo?.contractRef} />
          <InfoItem label="Sailing Week" value={shipment.shipmentInfo?.sailingWeek} />
          <InfoItem label="Commodity" value={shipment.shipmentInfo?.commodity} />
          <InfoItem label="HS Code" value={shipment.shipmentInfo?.hsCode} />
        </div>
      </div>

      {/* Shipping Details */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Shipping Details</h3>
        <div className="grid grid-cols-2 gap-4">
          <InfoItem label="Container Type" value={shipment.shipmentInfo?.containerType} />
          <InfoItem label="Container Quantity" value={shipment.shipmentInfo?.containerQuantity?.toString()} />
          <InfoItem label="Port of Loading" value={shipment.shippingDetails?.portOfLoading} />
          <InfoItem label="Port of Discharge" value={shipment.shippingDetails?.portOfDischarge} />
          <InfoItem label="Ocean Freight" value={shipment.shipmentInfo?.oceanFreight} />
          <InfoItem label="B/L Type" value={shipment.shipmentInfo?.blType} />
        </div>
      </div>
    </div>
  );
};

export default ShipmentDetails;