import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Save, Loader2, X, AlertCircle, Info } from 'lucide-react';
import { useShipmentStore } from '../stores/shipmentStore';
import { ShipmentStatus } from '../types/shipment';

const bookingConfirmationSchema = z.object({
  scmReference: z.string().min(1, 'SCM Reference is required'),
  notes: z.string().optional()
});

type BookingConfirmationData = z.infer<typeof bookingConfirmationSchema>;

interface BookingConfirmationFormProps {
  booking: any;
  onClose: () => void;
  onSuccess: () => void;
}

const BookingConfirmationForm = ({ booking, onClose, onSuccess }: BookingConfirmationFormProps) => {
  const { updateShipment } = useShipmentStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<BookingConfirmationData>({
    resolver: zodResolver(bookingConfirmationSchema),
    defaultValues: {
      scmReference: `SCM${Math.random().toString(36).substr(2, 6).toUpperCase()}`,
      notes: ''
    }
  });

  const onSubmit = async (data: BookingConfirmationData) => {
    setIsSubmitting(true);
    setError(null);

    try {
      await updateShipment(booking.id, {
        ...booking,
        status: ShipmentStatus.BOOKING_CONFIRMED,
        adminStatus: ShipmentStatus.PENDING_BOOKING_DETAILS,
        scmReference: data.scmReference,
        notes: data.notes,
        bookingRequestConfirmedAt: new Date().toISOString()
      });

      setShowSuccessAlert(true);
      
      // Show success alert for 2 seconds before closing
      setTimeout(() => {
        onSuccess();
        onClose();
      }, 2000);
    } catch (error) {
      console.error('Error confirming booking:', error);
      setError('Failed to confirm booking request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full">
        <div className="p-6 border-b border-gray-200 flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-bold text-gray-900">Confirm Booking Request</h2>
            <p className="text-sm text-gray-500">Reference: {booking.referenceNumber}</p>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-6">
          {/* Info Alert */}
          <div className="bg-blue-50 border-l-4 border-blue-400 p-4">
            <div className="flex">
              <Info className="h-5 w-5 text-blue-400 mr-2" />
              <div>
                <p className="text-sm text-blue-700">
                  After confirming this booking request:
                </p>
                <ul className="mt-2 text-sm text-blue-600 list-disc list-inside">
                  <li>The status will be updated to "Booking Confirmed"</li>
                  <li>You will need to enter booking details (vessel, ETD, etc.)</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">SCM Reference</label>
              <input
                type="text"
                {...register('scmReference')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              {errors.scmReference && (
                <p className="mt-1 text-sm text-red-600">{errors.scmReference.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Notes</label>
              <textarea
                {...register('notes')}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Add any notes about the booking..."
              />
            </div>
          </div>

          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <p className="text-sm text-red-600">{error}</p>
            </div>
          )}

          {showSuccessAlert && (
            <div className="rounded-md bg-green-50 p-4">
              <div className="flex">
                <Info className="h-5 w-5 text-green-400 mr-2" />
                <div>
                  <p className="text-sm font-medium text-green-800">
                    Booking confirmed successfully!
                  </p>
                  <p className="mt-1 text-sm text-green-700">
                    Please proceed to enter booking details.
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {isSubmitting ? (
                <>
                  <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                  Confirming...
                </>
              ) : (
                <>
                  <Save className="h-5 w-5 mr-2" />
                  Confirm Request
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookingConfirmationForm;