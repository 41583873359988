import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { 
  collection, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc, 
  getDoc, 
  getDocs, 
  query, 
  where, 
  orderBy, 
  limit,
  QueryConstraint,
  Timestamp
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { ShipmentStatus } from '../types/shipment';
import type { Shipment } from '../types/shipment';

interface ShipmentState {
  shipments: Shipment[];
  isLoading: boolean;
  error: string | null;
  isOffline: boolean;
  needsIndex: boolean;
  indexUrl: string | null;
  addShipment: (shipment: Partial<Shipment>) => Promise<string>;
  updateShipment: (id: string, updates: Partial<Shipment>) => Promise<void>;
  deleteBookingRequest: (id: string) => Promise<void>;
  fetchShipments: (userId?: string) => Promise<void>;
  getShipment: (id: string) => Promise<Shipment | null>;
  setError: (error: string | null) => void;
  retryConnection: () => Promise<void>;
}

export const useShipmentStore = create<ShipmentState>()(
  persist(
    (set, get) => ({
      shipments: [],
      isLoading: false,
      error: null,
      isOffline: false,
      needsIndex: false,
      indexUrl: null,

      addShipment: async (shipment) => {
        try {
          set({ isLoading: true, error: null });
          
          const now = Timestamp.now();
          const docData = {
            ...shipment,
            createdAt: now,
            updatedAt: now,
            status: shipment.status || ShipmentStatus.BOOKING_REQUESTED
          };

          const docRef = await addDoc(collection(db, 'shipments'), docData);
          
          const newShipment = {
            id: docRef.id,
            ...docData,
            createdAt: now.toDate().toISOString(),
            updatedAt: now.toDate().toISOString()
          } as Shipment;

          set(state => ({
            shipments: [newShipment, ...state.shipments],
            error: null
          }));

          return docRef.id;
        } catch (error: any) {
          console.error('Error adding shipment:', error);
          set({ error: error.message || 'Failed to add shipment' });
          throw error;
        } finally {
          set({ isLoading: false });
        }
      },

      updateShipment: async (id, updates) => {
        try {
          set({ isLoading: true, error: null });
          
          const docRef = doc(db, 'shipments', id);
          const docSnap = await getDoc(docRef);
          
          if (!docSnap.exists()) {
            throw new Error('Shipment not found');
          }

          const updateData = {
            ...updates,
            updatedAt: Timestamp.now()
          };

          await updateDoc(docRef, updateData);

          set(state => ({
            shipments: state.shipments.map(s => 
              s.id === id ? { 
                ...s, 
                ...updates,
                updatedAt: new Date().toISOString()
              } : s
            ),
            error: null
          }));
        } catch (error: any) {
          console.error('Error updating shipment:', error);
          set({ error: error.message || 'Failed to update shipment' });
          throw error;
        } finally {
          set({ isLoading: false });
        }
      },

      deleteBookingRequest: async (id) => {
        try {
          set({ isLoading: true, error: null });
          
          const docRef = doc(db, 'shipments', id);
          await deleteDoc(docRef);

          set(state => ({
            shipments: state.shipments.filter(s => s.id !== id),
            error: null
          }));
        } catch (error: any) {
          console.error('Error deleting booking request:', error);
          set({ error: error.message || 'Failed to delete booking request' });
          throw error;
        } finally {
          set({ isLoading: false });
        }
      },

      fetchShipments: async (userId) => {
        try {
          set({ isLoading: true, error: null, needsIndex: false, indexUrl: null });
          
          const constraints: QueryConstraint[] = [];
          
          if (userId) {
            constraints.push(where('userId', '==', userId));
          }
          
          constraints.push(orderBy('createdAt', 'desc'));
          constraints.push(limit(50));

          const q = query(collection(db, 'shipments'), ...constraints);
          const querySnapshot = await getDocs(q);
          
          const shipments = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate?.()?.toISOString() || doc.data().createdAt,
            updatedAt: doc.data().updatedAt?.toDate?.()?.toISOString() || doc.data().updatedAt
          })) as Shipment[];

          set({ shipments, error: null });
        } catch (error: any) {
          console.error('Error fetching shipments:', error);
          if (error.code === 'failed-precondition') {
            const indexUrl = error.message.match(/https:\/\/console\.firebase\.google\.com[^\s]*/)?.[0];
            set({ 
              needsIndex: true,
              indexUrl,
              error: 'Database index required. Please contact administrator.'
            });
          } else if (error.code === 'unavailable') {
            set({ 
              isOffline: true,
              error: 'Currently offline. Some features may be limited.'
            });
          } else {
            set({ error: error.message || 'Failed to fetch shipments' });
          }
          throw error;
        } finally {
          set({ isLoading: false });
        }
      },

      getShipment: async (id) => {
        try {
          const docRef = doc(db, 'shipments', id);
          const docSnap = await getDoc(docRef);
          
          if (docSnap.exists()) {
            const data = docSnap.data();
            return {
              id: docSnap.id,
              ...data,
              createdAt: data.createdAt?.toDate?.()?.toISOString() || data.createdAt,
              updatedAt: data.updatedAt?.toDate?.()?.toISOString() || data.updatedAt
            } as Shipment;
          }
          return null;
        } catch (error: any) {
          console.error('Error getting shipment:', error);
          set({ error: error.message || 'Failed to get shipment' });
          return null;
        }
      },

      setError: (error) => set({ error }),

      retryConnection: async () => {
        const state = get();
        set({ isOffline: false, error: null });
        await state.fetchShipments();
      }
    }),
    {
      name: 'shipment-storage',
      storage: localStorage,
      partialize: (state) => ({
        shipments: state.shipments
      })
    }
  )
);