import React, { useEffect } from 'react';
import { Ship, Package, Clock, AlertCircle, Calendar, Navigation, Truck, ArrowRight } from 'lucide-react';
import { useShipmentStore } from '../stores/shipmentStore';
import { useAuthStore } from '../stores/authStore';
import { useNavigate } from 'react-router-dom';
import ShipmentTracker from './ShipmentTracker';
import ShipmentStats from './ShipmentStats';
import UpcomingShipments from './UpcomingShipments';
import { ShipmentStatus } from '../types/shipment';
import { differenceInDays, parseISO, format, addDays, isBefore, isAfter, isSameDay } from 'date-fns';

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { shipments } = useShipmentStore();

  // Get upcoming loading dates (next 7 days)
  const getUpcomingLoadingDates = () => {
    const today = new Date();
    const nextWeek = addDays(today, 7);
    
    return shipments.filter(shipment => {
      if (!shipment.loadingDate || shipment.status === ShipmentStatus.ON_WAY_TO_PORT) return false;
      const loadingDate = parseISO(shipment.loadingDate);
      return isAfter(loadingDate, today) && isBefore(loadingDate, nextWeek);
    }).sort((a, b) => {
      return parseISO(a.loadingDate).getTime() - parseISO(b.loadingDate).getTime();
    });
  };

  // Get today's loading dates
  const getTodayLoadingDates = () => {
    const today = new Date();
    return shipments.filter(shipment => {
      if (!shipment.loadingDate || shipment.status === ShipmentStatus.ON_WAY_TO_PORT) return false;
      const loadingDate = parseISO(shipment.loadingDate);
      return isSameDay(loadingDate, today);
    }).sort((a, b) => {
      return a.loadingTime?.localeCompare(b.loadingTime || '') || 0;
    });
  };

  // Get departed shipments
  const getDepartedShipments = () => {
    return shipments.filter(s => 
      s.status === ShipmentStatus.DEPARTED && 
      s.departureDate && 
      s.estimatedArrival
    ).sort((a, b) => {
      const etaA = parseISO(a.estimatedArrival);
      const etaB = parseISO(b.estimatedArrival);
      return etaA.getTime() - etaB.getTime();
    });
  };

  const getStats = () => {
    if (!user) return null;

    switch (user.role) {
      case 'customer': {
        const pendingSLI = shipments.filter(s => 
          s.status === ShipmentStatus.WAITING_FOR_SLI
        ).length;

        const pendingLoadingDates = shipments.filter(s => 
          s.status === ShipmentStatus.WAITING_FOR_LOADING && 
          s.sliSubmitted && 
          !s.loadingDate
        ).length;

        const inTransit = shipments.filter(s =>
          s.status === ShipmentStatus.DEPARTED
        ).length;

        return {
          activeShipments: shipments.filter(s => 
            s.status !== ShipmentStatus.DELIVERED && 
            s.status !== ShipmentStatus.BOOKING_REQUESTED
          ).length,
          totalContainers: shipments.length,
          pendingSLI,
          pendingLoadingDates,
          inTransit
        };
      }

      case 'admin': {
        const pendingBookings = shipments.filter(s => 
          s.status === ShipmentStatus.BOOKING_REQUESTED
        ).length;

        const pendingBookingDetails = shipments.filter(s =>
          s.status === ShipmentStatus.BOOKING_CONFIRMED &&
          !s.bookingDetailsSubmitted
        ).length;

        const inTransit = shipments.filter(s =>
          s.status === ShipmentStatus.DEPARTED
        ).length;

        return {
          activeShipments: shipments.filter(s => 
            s.status !== ShipmentStatus.DELIVERED
          ).length,
          totalContainers: shipments.length,
          pendingBookings,
          pendingBookingDetails,
          inTransit
        };
      }

      case 'trucker': {
        const arrivingSoon = shipments.filter(s => {
          if (!s.estimatedArrival || s.status !== ShipmentStatus.DEPARTED) return false;
          const daysUntilArrival = differenceInDays(parseISO(s.estimatedArrival), new Date());
          return daysUntilArrival >= 0 && daysUntilArrival <= 5;
        }).length;

        const pendingAssignment = shipments.filter(s =>
          s.status === ShipmentStatus.DEPARTED && !s.truckerId
        ).length;

        return {
          activeShipments: shipments.filter(s => 
            s.status === ShipmentStatus.DEPARTED
          ).length,
          totalContainers: shipments.filter(s =>
            s.status === ShipmentStatus.DEPARTED &&
            differenceInDays(parseISO(s.estimatedArrival), new Date()) <= 15
          ).length,
          arrivingSoon,
          pendingAssignment
        };
      }

      default:
        return null;
    }
  };

  const stats = getStats();
  const upcomingLoadingDates = getUpcomingLoadingDates();
  const todayLoadingDates = getTodayLoadingDates();
  const departedShipments = getDepartedShipments();

  const handleViewShipment = (id: string) => {
    switch (user?.role) {
      case 'admin':
        navigate(`/shipments/${id}`);
        break;
      case 'customer':
        navigate(`/customer/shipments/${id}`);
        break;
      case 'trucker':
        navigate(`/trucker/shipments/${id}`);
        break;
    }
  };

  if (!stats) return null;

  return (
    <div className="space-y-6">
      {/* Today's Loading Dates Alert */}
      {todayLoadingDates.length > 0 && (
        <div className="bg-gradient-to-br from-red-50 to-rose-50 rounded-lg shadow-lg p-6">
          <div className="flex items-center space-x-2 mb-4">
            <Clock className="h-6 w-6 text-red-600" />
            <h2 className="text-lg font-semibold text-red-900">Today's Loading Schedule</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {todayLoadingDates.map(shipment => (
              <div
                key={shipment.id}
                onClick={() => handleViewShipment(shipment.id)}
                className="bg-white rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow cursor-pointer border-l-4 border-red-500"
              >
                <div className="flex items-start justify-between">
                  <div>
                    <p className="font-medium text-gray-900">
                      {shipment.loadingTime || 'Time not set'}
                    </p>
                    <p className="text-sm text-gray-600">Ref: {shipment.referenceNumber}</p>
                    <p className="text-sm text-gray-600">
                      {shipment.shipmentInfo.containerQuantity}x {shipment.shipmentInfo.containerType}
                    </p>
                  </div>
                  <div className="flex items-center">
                    {shipment.requiresTrucking && (
                      <Truck className="h-5 w-5 text-gray-400 mr-2" />
                    )}
                    <ArrowRight className="h-5 w-5 text-gray-400" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Upcoming Loading Dates Alert */}
      {upcomingLoadingDates.length > 0 && (
        <div className="bg-gradient-to-br from-amber-50 to-yellow-50 rounded-lg shadow-lg p-6">
          <div className="flex items-center space-x-2 mb-4">
            <Calendar className="h-6 w-6 text-amber-600" />
            <h2 className="text-lg font-semibold text-amber-900">Upcoming Loading Dates</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {upcomingLoadingDates.map(shipment => (
              <div
                key={shipment.id}
                onClick={() => handleViewShipment(shipment.id)}
                className="bg-white rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow cursor-pointer"
              >
                <div className="flex items-start justify-between">
                  <div>
                    <p className="font-medium text-gray-900">
                      {format(parseISO(shipment.loadingDate), 'MMM d, yyyy')}
                      {shipment.loadingTime && ` at ${shipment.loadingTime}`}
                    </p>
                    <p className="text-sm text-gray-600">Ref: {shipment.referenceNumber}</p>
                    <p className="text-sm text-gray-600">
                      {shipment.shipmentInfo.containerQuantity}x {shipment.shipmentInfo.containerType}
                    </p>
                  </div>
                  <div className="flex items-center">
                    {shipment.requiresTrucking && (
                      <Truck className="h-5 w-5 text-gray-400 mr-2" />
                    )}
                    <ArrowRight className="h-5 w-5 text-gray-400" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <StatCard
          title="Active Shipments"
          value={stats.activeShipments.toString()}
          icon={<Ship className="h-6 w-6 text-blue-600" />}
          gradient="from-blue-50 to-indigo-50"
          iconBg="bg-blue-100"
        />
        <StatCard
          title={user?.role === 'trucker' ? 'Containers (Next 15 Days)' : 'Total Containers'}
          value={stats.totalContainers.toString()}
          icon={<Package className="h-6 w-6 text-emerald-600" />}
          gradient="from-emerald-50 to-teal-50"
          iconBg="bg-emerald-100"
        />
        {user?.role === 'admin' && (
          <>
            <StatCard
              title="Pending Bookings"
              value={stats.pendingBookings.toString()}
              icon={<Clock className="h-6 w-6 text-amber-600" />}
              gradient="from-amber-50 to-yellow-50"
              iconBg="bg-amber-100"
            />
            <StatCard
              title="In Transit"
              value={stats.inTransit.toString()}
              icon={<Navigation className="h-6 w-6 text-purple-600" />}
              gradient="from-purple-50 to-fuchsia-50"
              iconBg="bg-purple-100"
            />
          </>
        )}
        {user?.role === 'customer' && (
          <>
            <StatCard
              title="Pending SLI"
              value={stats.pendingSLI.toString()}
              icon={<Clock className="h-6 w-6 text-amber-600" />}
              gradient="from-amber-50 to-yellow-50"
              iconBg="bg-amber-100"
            />
            <StatCard
              title="In Transit"
              value={stats.inTransit.toString()}
              icon={<Navigation className="h-6 w-6 text-purple-600" />}
              gradient="from-purple-50 to-fuchsia-50"
              iconBg="bg-purple-100"
            />
          </>
        )}
        {user?.role === 'trucker' && (
          <>
            <StatCard
              title="Arriving Soon"
              value={stats.arrivingSoon.toString()}
              icon={<Clock className="h-6 w-6 text-amber-600" />}
              gradient="from-amber-50 to-yellow-50"
              iconBg="bg-amber-100"
            />
            <StatCard
              title="Pending Assignment"
              value={stats.pendingAssignment.toString()}
              icon={<AlertCircle className="h-6 w-6 text-orange-600" />}
              gradient="from-orange-50 to-amber-50"
              iconBg="bg-orange-100"
              alert={stats.pendingAssignment > 0}
            />
          </>
        )}
      </div>

      {/* Departed Shipments */}
      {departedShipments.length > 0 && (
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center space-x-2 mb-4">
            <Navigation className="h-6 w-6 text-blue-600" />
            <h2 className="text-lg font-semibold">Shipments in Transit</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {departedShipments.map(shipment => {
              const departureDate = parseISO(shipment.departureDate);
              const eta = parseISO(shipment.estimatedArrival);
              const today = new Date();
              const totalDays = differenceInDays(eta, departureDate);
              const remainingDays = differenceInDays(eta, today);
              const progress = Math.max(0, Math.min(100, ((totalDays - remainingDays) / totalDays) * 100));

              return (
                <div 
                  key={shipment.id}
                  onClick={() => handleViewShipment(shipment.id)}
                  className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg p-4 cursor-pointer hover:from-blue-100 hover:to-indigo-100 transition-colors shadow-sm"
                >
                  <div className="flex justify-between items-start mb-3">
                    <div>
                      <h3 className="font-medium text-blue-900">{shipment.vessel}</h3>
                      <p className="text-sm text-blue-700">Ref: {shipment.referenceNumber}</p>
                    </div>
                    <div className="text-right">
                      <p className="text-sm font-medium text-blue-900">
                        {remainingDays} days remaining
                      </p>
                      <p className="text-xs text-blue-700">
                        ETA: {format(eta, 'MMM d, yyyy')}
                      </p>
                    </div>
                  </div>

                  {/* Progress bar */}
                  <div className="w-full bg-blue-200 rounded-full h-2.5 overflow-hidden">
                    <div 
                      className="bg-gradient-to-r from-blue-500 to-indigo-500 h-2.5 rounded-full transition-all duration-500"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>

                  <div className="mt-2 flex justify-between text-xs text-blue-700">
                    <span>{shipment.shippingDetails.portOfLoading}</span>
                    <span>{shipment.shippingDetails.portOfDischarge}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* Main Content */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Shipment Tracking */}
        <div className="lg:col-span-2">
          <ShipmentTracker />
        </div>

        {/* Upcoming Shipments */}
        <div>
          <UpcomingShipments />
        </div>
      </div>

      {/* Additional Info */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <ShipmentStats />
      </div>
    </div>
  );
};

interface StatCardProps {
  title: string;
  value: string;
  icon: React.ReactNode;
  gradient: string;
  iconBg: string;
  alert?: boolean;
}

const StatCard = ({ title, value, icon, gradient, iconBg, alert }: StatCardProps) => (
  <div className={`bg-gradient-to-br ${gradient} rounded-lg shadow-sm p-6 ${alert ? 'ring-2 ring-orange-500' : ''}`}>
    <div className="flex justify-between items-start">
      <div>
        <p className="text-sm font-medium text-gray-600">{title}</p>
        <p className="mt-2 text-3xl font-bold text-gray-900">{value}</p>
      </div>
      <div className={`p-2 ${iconBg} rounded-lg`}>
        {icon}
      </div>
    </div>
  </div>
);

export default Dashboard;