import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { TOP_PORTS, TOP_COUNTRIES, CHILE_PORTS } from '../../data/ports';
import { CONTAINER_OPTIONS } from '../../data/containers';
import type { BookingFormData } from './types';

interface ShippingSectionProps {
  register: UseFormRegister<BookingFormData>;
  errors: any;
  selectedCountry: string;
  onCountryChange: (country: string) => void;
}

const ShippingSection = ({
  register,
  errors,
  selectedCountry,
  onCountryChange
}: ShippingSectionProps) => {
  return (
    <div>
      <h3 className="text-lg font-medium text-gray-900 mb-4">Shipping Details</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Origin Country - Fixed to Chile */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Origin Country</label>
          <input
            type="text"
            value="Chile"
            disabled
            className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50 shadow-sm text-gray-500"
          />
          <input type="hidden" {...register('originCountry')} value="Chile" />
        </div>

        {/* Port of Loading - Chilean Ports */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Port of Loading</label>
          <select
            {...register('portOfLoading')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">Select Port</option>
            {CHILE_PORTS.map(port => (
              <option key={port.code} value={port.code}>
                {port.name}
              </option>
            ))}
          </select>
          {errors.portOfLoading && (
            <p className="mt-1 text-sm text-red-600">{errors.portOfLoading.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Container Type</label>
          <select
            {...register('containerType')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">Select Type</option>
            {CONTAINER_OPTIONS.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {errors.containerType && (
            <p className="mt-1 text-sm text-red-600">{errors.containerType.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Container Quantity</label>
          <input
            type="number"
            min="1"
            {...register('containerQuantity', { valueAsNumber: true })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
          {errors.containerQuantity && (
            <p className="mt-1 text-sm text-red-600">{errors.containerQuantity.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Destination Country</label>
          <select
            {...register('destinationCountry')}
            onChange={(e) => {
              onCountryChange(e.target.value);
              register('destinationCountry').onChange(e);
            }}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">Select Country</option>
            {TOP_COUNTRIES.map(country => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </select>
          {errors.destinationCountry && (
            <p className="mt-1 text-sm text-red-600">{errors.destinationCountry.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Port of Discharge</label>
          <select
            {...register('portOfDischarge')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">Select Port</option>
            {selectedCountry && TOP_PORTS[selectedCountry as keyof typeof TOP_PORTS]?.map(port => (
              <option key={port.code} value={port.code}>
                {port.name}
              </option>
            ))}
          </select>
          {errors.portOfDischarge && (
            <p className="mt-1 text-sm text-red-600">{errors.portOfDischarge.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Ocean Freight</label>
          <select
            {...register('oceanFreight')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">Select Payment Type</option>
            <option value="prepaid">Prepaid</option>
            <option value="collect">Collect</option>
          </select>
          {errors.oceanFreight && (
            <p className="mt-1 text-sm text-red-600">{errors.oceanFreight.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">B/L Type</label>
          <select
            {...register('blType')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="">Select B/L Type</option>
            <option value="original">Original</option>
            <option value="seawaybill">Seawaybill</option>
            <option value="telex">Telex Release</option>
          </select>
          {errors.blType && (
            <p className="mt-1 text-sm text-red-600">{errors.blType.message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShippingSection;