import React from 'react';
import { Info, Calendar, Clock, Ship } from 'lucide-react';
import { format, parseISO, isValid } from 'date-fns';
import { ShipmentStatus } from '../../types/shipment';

interface BookingInfoBannerProps {
  bookingNumber?: string;
  mblNumber?: string;
  vessel?: string;
  etd?: string;
  eta?: string;
  portOfLoading?: string;
  portOfDischarge?: string;
  containerType?: string;
  containerQuantity?: number;
  shippingLine?: string;
  reference?: string;
  status?: string;
  departureDate?: string;
  departureTime?: string;
}

const BookingInfoBanner = ({
  bookingNumber,
  mblNumber,
  vessel,
  etd,
  eta,
  portOfLoading,
  portOfDischarge,
  containerType,
  containerQuantity,
  shippingLine,
  reference,
  status,
  departureDate,
  departureTime
}: BookingInfoBannerProps) => {
  const formatDate = (dateString?: string) => {
    if (!dateString) return null;
    try {
      const date = parseISO(dateString);
      return isValid(date) ? format(date, 'MMM d, yyyy') : null;
    } catch {
      return null;
    }
  };

  const formatTime = (timeString?: string) => {
    if (!timeString) return null;
    try {
      const [hours, minutes] = timeString.split(':');
      return format(new Date().setHours(parseInt(hours), parseInt(minutes)), 'h:mm a');
    } catch {
      return timeString;
    }
  };

  // Show departure banner if status is DEPARTED
  if (status === ShipmentStatus.DEPARTED && departureDate && departureTime) {
    return (
      <div className="bg-gradient-to-br from-green-50 to-emerald-50 rounded-lg p-6 relative overflow-hidden shadow-sm">
        <div className="absolute right-0 top-0 transform translate-x-8 -translate-y-8">
          <Ship className="h-32 w-32 text-emerald-100" />
        </div>
        <div className="relative z-10">
          <div className="flex items-start">
            <Ship className="h-8 w-8 text-emerald-600 mr-3" />
            <div>
              <h3 className="text-xl font-bold text-emerald-800">Vessel Has Departed!</h3>
              <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-emerald-700">
                    <span className="font-medium">Vessel:</span> {vessel}
                  </p>
                  <p className="text-sm text-emerald-700">
                    <span className="font-medium">Departed:</span> {formatDate(departureDate)} at {formatTime(departureTime)}
                  </p>
                  <p className="text-sm text-emerald-700">
                    <span className="font-medium">ETA:</span> {formatDate(eta)}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-emerald-700">
                    <span className="font-medium">From:</span> {portOfLoading}
                  </p>
                  <p className="text-sm text-emerald-700">
                    <span className="font-medium">To:</span> {portOfDischarge}
                  </p>
                  <p className="text-sm text-emerald-700">
                    <span className="font-medium">Container:</span> {containerQuantity}x {containerType}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Regular booking info banner
  return (
    <div className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg p-6 shadow-sm">
      <div className="flex items-start">
        <Info className="h-5 w-5 text-blue-500 mt-0.5 mr-3" />
        <div className="flex-1">
          <h3 className="text-sm font-medium text-blue-800 mb-3">Booking Information</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-2">
            <div className="space-y-2">
              {bookingNumber && (
                <p className="text-sm text-blue-700">
                  <span className="font-medium">Booking #:</span> {bookingNumber}
                </p>
              )}
              {mblNumber && (
                <p className="text-sm text-blue-700">
                  <span className="font-medium">MBL #:</span> {mblNumber}
                </p>
              )}
              {vessel && (
                <p className="text-sm text-blue-700">
                  <span className="font-medium">Vessel:</span> {vessel}
                </p>
              )}
              {shippingLine && (
                <p className="text-sm text-blue-700">
                  <span className="font-medium">Line:</span> {shippingLine}
                </p>
              )}
              {reference && (
                <p className="text-sm text-blue-700">
                  <span className="font-medium">Ref:</span> {reference}
                </p>
              )}
            </div>
            <div className="space-y-2">
              {etd && (
                <p className="text-sm text-blue-700">
                  <span className="font-medium">ETD:</span> {formatDate(etd)}
                </p>
              )}
              {eta && (
                <p className="text-sm text-blue-700">
                  <span className="font-medium">ETA:</span> {formatDate(eta)}
                </p>
              )}
              {portOfLoading && (
                <p className="text-sm text-blue-700">
                  <span className="font-medium">POL:</span> {portOfLoading}
                </p>
              )}
              {portOfDischarge && (
                <p className="text-sm text-blue-700">
                  <span className="font-medium">POD:</span> {portOfDischarge}
                </p>
              )}
              {containerType && (
                <p className="text-sm text-blue-700">
                  <span className="font-medium">Container:</span> {containerQuantity ? `${containerQuantity}x ` : ''}{containerType}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingInfoBanner;