import React, { useState, useEffect } from 'react';
import { Search, Filter, Loader2, AlertCircle, Ship, Package, MapPin, Calendar, FileText } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../stores/authStore';
import { useShipmentStore } from '../stores/shipmentStore';
import ShipmentList from './ShipmentList';
import BookingDetailsForm from './BookingDetailsForm';
import BookingConfirmationForm from './BookingConfirmationForm';
import LoadingDateConfirmation from './admin/LoadingDateConfirmation';
import { ShipmentStatus } from '../types/shipment';

const AdminPortal = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [showBookingDetails, setShowBookingDetails] = useState(false);
  const [showLoadingDateConfirmation, setShowLoadingDateConfirmation] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<any>(null);
  const { user } = useAuthStore();
  const { 
    shipments, 
    fetchShipments, 
    error: shipmentError, 
    isOffline, 
    retryConnection 
  } = useShipmentStore();

  useEffect(() => {
    const loadData = async () => {
      if (user?.id) {
        try {
          setIsLoading(true);
          await fetchShipments();
        } catch (error) {
          console.error('Error loading shipments:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    loadData();
  }, [user?.id, fetchShipments]);

  const handleViewShipment = (id: string) => {
    navigate(`/shipments/${id}`);
  };

  const handleEnterBookingDetails = (shipment: any) => {
    setSelectedBooking(shipment);
    setShowBookingDetails(true);
  };

  const handleConfirmLoadingDate = (shipment: any) => {
    if (shipment.status === ShipmentStatus.WAITING_FOR_LOADING && 
        shipment.plannedLoadingDate && 
        shipment.documents?.mandato && 
        shipment.documents?.proformaInvoice) {
      setSelectedBooking(shipment);
      setShowLoadingDateConfirmation(true);
    }
  };

  // Filter shipments by status
  const pendingBookings = shipments.filter(s => 
    s.status === ShipmentStatus.BOOKING_REQUESTED
  );

  const confirmedShipments = shipments.filter(s => 
    s.status !== ShipmentStatus.BOOKING_REQUESTED
  );

  if (!user) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6">
        <p className="text-center text-gray-600">Please log in to access this page.</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-center h-32">
          <Loader2 className="h-8 w-8 text-blue-600 animate-spin" />
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {(shipmentError || isOffline) && (
        <div className={`bg-${isOffline ? 'yellow' : 'red'}-50 border-l-4 border-${isOffline ? 'yellow' : 'red'}-400 p-4`}>
          <div className="flex">
            <AlertCircle className={`h-5 w-5 text-${isOffline ? 'yellow' : 'red'}-400 mr-2`} />
            <div>
              <p className={`text-sm text-${isOffline ? 'yellow' : 'red'}-700`}>
                {isOffline 
                  ? 'Currently working offline. Some features may be limited.' 
                  : shipmentError}
              </p>
              <button
                onClick={retryConnection}
                className={`mt-2 text-sm text-${isOffline ? 'yellow' : 'red'}-600 hover:text-${isOffline ? 'yellow' : 'red'}-800 underline`}
              >
                Retry Connection
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Professional Header */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-2xl font-bold text-gray-900">Admin Portal</h1>
      </div>

      {/* Pending Booking Requests Section */}
      <div className="bg-white rounded-lg shadow-lg">
        <div className="p-6 border-b">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-xl font-bold text-gray-900">Pending Booking Requests</h2>
              <p className="text-sm text-gray-500 mt-1">Review and confirm new booking requests</p>
            </div>
            {pendingBookings.length > 0 && (
              <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                {pendingBookings.length} pending
              </span>
            )}
          </div>
        </div>

        <div className="p-6">
          {pendingBookings.length === 0 ? (
            <p className="text-center text-gray-500">No pending booking requests</p>
          ) : (
            <div className="space-y-6">
              {pendingBookings.map(booking => (
                <div key={booking.id} className="border rounded-lg p-6 bg-yellow-50">
                  <div className="flex justify-between items-start mb-6">
                    <h3 className="text-lg font-medium text-gray-900">
                      Booking Request: {booking.referenceNumber}
                    </h3>
                    <button
                      onClick={() => setSelectedBooking(booking)}
                      className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 shadow-sm"
                    >
                      Confirm Request
                    </button>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Basic Information */}
                    <div className="space-y-2">
                      <div className="flex items-center space-x-2 text-gray-700">
                        <Package className="h-4 w-4 text-blue-600" />
                        <span className="font-medium">Booking Details</span>
                      </div>
                      <p className="text-sm text-gray-600">Reference #: {booking.referenceNumber}</p>
                      <p className="text-sm text-gray-600">PO #: {booking.shipmentInfo.customerPO}</p>
                      <p className="text-sm text-gray-600">SSL Contract #: {booking.shipmentInfo.contractRef}</p>
                      <p className="text-sm text-gray-600">Sailing Week: {booking.shipmentInfo.sailingWeek}</p>
                    </div>

                    {/* Cargo Information */}
                    <div className="space-y-2">
                      <div className="flex items-center space-x-2 text-gray-700">
                        <Ship className="h-4 w-4 text-blue-600" />
                        <span className="font-medium">Cargo Information</span>
                      </div>
                      <p className="text-sm text-gray-600">Commodity: {booking.shipmentInfo.commodity}</p>
                      <p className="text-sm text-gray-600">HS Code: {booking.shipmentInfo.hsCode}</p>
                      <p className="text-sm text-gray-600">
                        Weight: {booking.shipmentInfo.weight} {booking.shipmentInfo.weightUnit}
                      </p>
                      <p className="text-sm text-gray-600">
                        Volume: {booking.shipmentInfo.volume} {booking.shipmentInfo.volumeUnit}
                      </p>
                    </div>

                    {/* Container & Shipping */}
                    <div className="space-y-2">
                      <div className="flex items-center space-x-2 text-gray-700">
                        <Package className="h-4 w-4 text-indigo-600" />
                        <span className="font-medium">Container & Shipping</span>
                      </div>
                      <p className="text-sm text-gray-600">
                        Container: {booking.shipmentInfo.containerQuantity}x {booking.shipmentInfo.containerType}
                      </p>
                      <p className="text-sm text-gray-600">Port of Loading: {booking.shippingDetails.portOfLoading}</p>
                      <p className="text-sm text-gray-600">Port of Discharge: {booking.shippingDetails.portOfDischarge}</p>
                      <p className="text-sm text-gray-600">Ocean Freight: {booking.shippingDetails.oceanFreight}</p>
                      <p className="text-sm text-gray-600">B/L Type: {booking.shippingDetails.blType}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Confirmed Shipments Section */}
      <div className="bg-white rounded-lg shadow-lg">
        <div className="p-6 border-b">
          <h2 className="text-xl font-bold text-gray-900">Confirmed Shipments</h2>

          <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search by Reference #, PO#, booking..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-4 py-2 border rounded-md pl-10"
              />
              <Search className="h-5 w-5 text-gray-400 absolute left-3 top-2.5" />
            </div>

            <div className="flex items-center space-x-2">
              <Filter className="h-5 w-5 text-gray-400" />
              <select
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
                className="block w-full px-3 py-2 border rounded-md"
              >
                <option value="all">All Status</option>
                <option value={ShipmentStatus.BOOKING_CONFIRMED}>Booking Confirmed</option>
                <option value={ShipmentStatus.WAITING_FOR_SLI}>Waiting for SLI</option>
                <option value={ShipmentStatus.WAITING_FOR_LOADING}>Waiting for Loading</option>
                <option value={ShipmentStatus.LOADING_CONFIRMED}>Loading Confirmed</option>
                <option value={ShipmentStatus.ON_WAY_TO_PORT}>On Way to Port</option>
                <option value={ShipmentStatus.WAITING_FOR_SAILING}>Waiting for Sailing</option>
                <option value={ShipmentStatus.DEPARTED}>Departed</option>
                <option value={ShipmentStatus.DELIVERED}>Delivered</option>
              </select>
            </div>
          </div>
        </div>

        <ShipmentList 
          shipments={confirmedShipments}
          searchTerm={searchTerm}
          filterStatus={filterStatus}
          onView={handleViewShipment}
          onEnterBookingDetails={handleEnterBookingDetails}
          onConfirmLoadingDate={handleConfirmLoadingDate}
          isAdmin={true}
        />
      </div>

      {/* Booking Confirmation Modal */}
      {selectedBooking && !showBookingDetails && !showLoadingDateConfirmation && (
        <BookingConfirmationForm 
          booking={selectedBooking}
          onClose={() => setSelectedBooking(null)}
          onSuccess={() => {
            setSelectedBooking(null);
            fetchShipments();
          }}
        />
      )}

      {/* Booking Details Modal */}
      {showBookingDetails && selectedBooking && (
        <BookingDetailsForm 
          shipment={selectedBooking}
          onClose={() => {
            setSelectedBooking(null);
            setShowBookingDetails(false);
          }}
          onSuccess={() => {
            setSelectedBooking(null);
            setShowBookingDetails(false);
            fetchShipments();
          }}
        />
      )}

      {/* Loading Date Confirmation Modal */}
      {showLoadingDateConfirmation && selectedBooking && (
        <LoadingDateConfirmation
          shipment={selectedBooking}
          onClose={() => {
            setShowLoadingDateConfirmation(false);
            setSelectedBooking(null);
          }}
          onSuccess={() => {
            setShowLoadingDateConfirmation(false);
            setSelectedBooking(null);
            fetchShipments();
          }}
        />
      )}
    </div>
  );
};

export default AdminPortal;