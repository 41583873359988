export const PRESET_SHIPPERS = [
  {
    id: 'sunsweet',
    name: 'SUNSWEET CHILE SPA',
    contactPerson: 'Aimee Anez',
    email: 'aanez@sunsweet.com',
    phone: '+56 9 5762 1801',
    taxId: '76.371.350-4',
    address: '2969. OF 302. PISO 3',
    city: 'Vitacura, Las Condes RM',
    country: 'Chile'
  }
] as const;