import React from 'react';
import { Calendar, Clock, CheckCircle2 } from 'lucide-react';
import { format, parseISO } from 'date-fns';
import { ShipmentStatus } from '../types/shipment';

interface CustomerLoadingConfirmationProps {
  shipment: any;
}

const CustomerLoadingConfirmation = ({ shipment }: CustomerLoadingConfirmationProps) => {
  const formatDate = (dateString: string) => {
    try {
      return format(parseISO(dateString), 'MMMM d, yyyy');
    } catch {
      return dateString;
    }
  };

  const formatTime = (timeString: string) => {
    try {
      const [hours, minutes] = timeString.split(':');
      return format(new Date().setHours(parseInt(hours), parseInt(minutes)), 'h:mm a');
    } catch {
      return timeString;
    }
  };

  // Don't show the alert if shipment is already on way to port or beyond
  if (!shipment.loadingDateConfirmed || 
      shipment.status === ShipmentStatus.ON_WAY_TO_PORT ||
      shipment.status === ShipmentStatus.WAITING_FOR_SAILING ||
      shipment.status === ShipmentStatus.DEPARTED) {
    return null;
  }

  return (
    <div className="bg-green-50 rounded-lg p-6 mb-6">
      <div className="flex items-start">
        <CheckCircle2 className="h-6 w-6 text-green-500 mt-1 mr-3" />
        <div>
          <h3 className="text-lg font-medium text-green-800">Loading Date Confirmed</h3>
          <p className="mt-2 text-sm text-green-700">
            Your loading date and documents have been verified and confirmed by our team.
          </p>

          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex items-center space-x-2 text-green-700">
              <Calendar className="h-5 w-5" />
              <span>Date: {formatDate(shipment.confirmedLoadingDate)}</span>
            </div>
            <div className="flex items-center space-x-2 text-green-700">
              <Clock className="h-5 w-5" />
              <span>Time: {formatTime(shipment.confirmedLoadingTime)}</span>
            </div>
          </div>

          {shipment.confirmationNotes && (
            <div className="mt-4 text-sm text-green-700">
              <p className="font-medium">Additional Notes:</p>
              <p>{shipment.confirmationNotes}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerLoadingConfirmation;