import React, { useState, useEffect } from 'react';
import { Search, Filter, Loader2, Plus, AlertCircle, FileText } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../stores/authStore';
import { useShipmentStore } from '../stores/shipmentStore';
import ShipmentList from './ShipmentList';
import BookingRequestForm from './booking/BookingRequestForm';
import BulkBookingUpload from './BulkBookingUpload';
import LoadingDateForm from './LoadingDateForm';
import LoadingCompletionForm from './LoadingCompletionForm';
import SLIConfirmationForm from './SLIConfirmationForm';
import { ShipmentStatus } from '../types/shipment';

const CustomerPortal = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [showBookingForm, setShowBookingForm] = useState(false);
  const [showBulkUpload, setShowBulkUpload] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState<any>(null);
  const [showLoadingDateForm, setShowLoadingDateForm] = useState(false);
  const [showLoadingCompletionForm, setShowLoadingCompletionForm] = useState(false);
  const [showSLIForm, setShowSLIForm] = useState(false);
  const { user } = useAuthStore();
  const { 
    shipments, 
    fetchShipments, 
    error: shipmentError,
    isOffline,
    needsIndex,
    indexUrl,
    retryConnection 
  } = useShipmentStore();

  useEffect(() => {
    const loadData = async () => {
      if (user?.id) {
        try {
          setIsLoading(true);
          await fetchShipments(user.id);
        } catch (error) {
          console.error('Error loading shipments:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadData();
  }, [user?.id, fetchShipments]);

  const handleViewShipment = (id: string) => {
    navigate(`/customer/shipments/${id}`);
  };

  const handleSetPlannedLoading = (shipment: any) => {
    if (shipment.status === ShipmentStatus.WAITING_FOR_LOADING) {
      setSelectedShipment(shipment);
      setShowLoadingDateForm(true);
    }
  };

  const handleCompleteLoading = (shipment: any) => {
    if (shipment.status === ShipmentStatus.LOADING_CONFIRMED) {
      setSelectedShipment(shipment);
      setShowLoadingCompletionForm(true);
    }
  };

  const handleSLIUpload = (shipment: any) => {
    setSelectedShipment(shipment);
    setShowSLIForm(true);
  };

  const handleBookingSuccess = async () => {
    setShowBookingForm(false);
    setShowBulkUpload(false);
    if (user?.id) {
      try {
        await fetchShipments(user.id);
      } catch (error) {
        console.error('Error refreshing shipments:', error);
      }
    }
  };

  if (!user) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6">
        <p className="text-center text-gray-600">Please log in to access this page.</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-center h-32">
          <Loader2 className="h-8 w-8 text-blue-600 animate-spin" />
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {(shipmentError || isOffline || needsIndex) && (
        <div className={`bg-${isOffline ? 'yellow' : needsIndex ? 'blue' : 'red'}-50 border-l-4 border-${isOffline ? 'yellow' : needsIndex ? 'blue' : 'red'}-400 p-4`}>
          <div className="flex">
            <AlertCircle className={`h-5 w-5 text-${isOffline ? 'yellow' : needsIndex ? 'blue' : 'red'}-400 mr-2`} />
            <div>
              <p className={`text-sm text-${isOffline ? 'yellow' : needsIndex ? 'blue' : 'red'}-700`}>
                {isOffline 
                  ? 'Currently working offline. Some features may be limited.'
                  : needsIndex 
                    ? 'Database index required. Please contact administrator.'
                    : shipmentError}
              </p>
              {needsIndex && indexUrl && (
                <p className="mt-2 text-sm text-blue-600">
                  Administrator can fix this by{' '}
                  <a 
                    href={indexUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline hover:text-blue-800"
                  >
                    creating the required index
                  </a>
                </p>
              )}
              {(isOffline || !needsIndex) && (
                <button
                  onClick={retryConnection}
                  className={`mt-2 text-sm text-${isOffline ? 'yellow' : 'red'}-600 hover:text-${isOffline ? 'yellow' : 'red'}-800 underline`}
                >
                  Retry Connection
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="bg-white rounded-lg shadow-lg">
        <div className="p-6 border-b">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold text-gray-900">Customer Portal</h1>
            <div className="flex space-x-2">
              <button
                onClick={() => setShowBulkUpload(true)}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                <Plus className="h-4 w-4 mr-2" />
                Bulk Upload
              </button>
              <button
                onClick={() => setShowBookingForm(true)}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
              >
                <Plus className="h-4 w-4 mr-2" />
                New Booking Request
              </button>
            </div>
          </div>

          <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search by Reference #, PO#..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-4 py-2 border rounded-md pl-10"
              />
              <Search className="h-5 w-5 text-gray-400 absolute left-3 top-2.5" />
            </div>

            <div className="flex items-center space-x-2">
              <Filter className="h-5 w-5 text-gray-400" />
              <select
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
                className="block w-full px-3 py-2 border rounded-md"
              >
                <option value="all">All Status</option>
                <option value={ShipmentStatus.BOOKING_REQUESTED}>Booking Requested</option>
                <option value={ShipmentStatus.BOOKING_CONFIRMED}>Booking Confirmed</option>
                <option value={ShipmentStatus.WAITING_FOR_SLI}>Waiting for SLI</option>
                <option value={ShipmentStatus.WAITING_FOR_LOADING}>Waiting for Loading</option>
                <option value={ShipmentStatus.LOADING_CONFIRMED}>Loading Confirmed</option>
                <option value={ShipmentStatus.ON_WAY_TO_PORT}>On Way to Port</option>
                <option value={ShipmentStatus.WAITING_FOR_SAILING}>Waiting for Sailing</option>
                <option value={ShipmentStatus.DEPARTED}>Departed</option>
                <option value={ShipmentStatus.DELIVERED}>Delivered</option>
              </select>
            </div>
          </div>
        </div>

        <ShipmentList 
          shipments={shipments}
          searchTerm={searchTerm}
          filterStatus={filterStatus}
          onView={handleViewShipment}
          onSetPlannedLoading={handleSetPlannedLoading}
          onCompleteLoading={handleCompleteLoading}
          onUploadSLI={handleSLIUpload}
          isCustomer={true}
        />
      </div>

      {showBookingForm && (
        <BookingRequestForm 
          onClose={() => setShowBookingForm(false)}
          onSuccess={handleBookingSuccess}
        />
      )}

      {showBulkUpload && (
        <BulkBookingUpload
          onClose={() => setShowBulkUpload(false)}
          onSuccess={handleBookingSuccess}
        />
      )}

      {showLoadingDateForm && selectedShipment && (
        <LoadingDateForm
          shipment={selectedShipment}
          onClose={() => {
            setShowLoadingDateForm(false);
            setSelectedShipment(null);
          }}
          onSuccess={() => {
            setShowLoadingDateForm(false);
            setSelectedShipment(null);
            if (user?.id) {
              fetchShipments(user.id);
            }
          }}
        />
      )}

      {showLoadingCompletionForm && selectedShipment && (
        <LoadingCompletionForm
          shipment={selectedShipment}
          onClose={() => {
            setShowLoadingCompletionForm(false);
            setSelectedShipment(null);
          }}
          onSuccess={() => {
            setShowLoadingCompletionForm(false);
            setSelectedShipment(null);
            if (user?.id) {
              fetchShipments(user.id);
            }
          }}
        />
      )}

      {showSLIForm && selectedShipment && (
        <SLIConfirmationForm
          shipment={selectedShipment}
          onClose={() => {
            setShowSLIForm(false);
            setSelectedShipment(null);
          }}
          onSuccess={() => {
            setShowSLIForm(false);
            setSelectedShipment(null);
            if (user?.id) {
              fetchShipments(user.id);
            }
          }}
        />
      )}
    </div>
  );
};

export default CustomerPortal;