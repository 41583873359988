import React from 'react';
import type { SectionProps } from './types';

const ReferenceSection = ({ register, errors }: SectionProps) => {
  return (
    <div>
      <h3 className="text-lg font-medium text-gray-900 mb-4">Booking Reference</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Reference # <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            {...register('referenceNumber')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            placeholder="Enter unique reference number for this booking"
          />
          {errors.referenceNumber && (
            <p className="mt-1 text-sm text-red-600">{errors.referenceNumber.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            SSL Contract # <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            {...register('contractRef')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            placeholder="Enter SSL contract number"
          />
          {errors.contractRef && (
            <p className="mt-1 text-sm text-red-600">{errors.contractRef.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Customer PO <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            {...register('customerPO')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            placeholder="Enter PO number"
          />
          {errors.customerPO && (
            <p className="mt-1 text-sm text-red-600">{errors.customerPO.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Sailing Week <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            {...register('sailingWeek')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            placeholder="Enter sailing week"
          />
          {errors.sailingWeek && (
            <p className="mt-1 text-sm text-red-600">{errors.sailingWeek.message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReferenceSection;