import React from 'react';
import { Calendar, Ship } from 'lucide-react';
import { format, isValid, parseISO } from 'date-fns';
import { useShipmentStore } from '../stores/shipmentStore';

const UpcomingShipments = () => {
  const { shipments } = useShipmentStore();

  // Get upcoming shipments (those with future estimated arrival dates)
  const upcomingShipments = shipments
    .filter(shipment => {
      try {
        const lastReceivingDate = shipment.shipmentInfo?.lastReceivingDate;
        if (!lastReceivingDate) return false;
        
        const date = typeof lastReceivingDate === 'string' 
          ? parseISO(lastReceivingDate)
          : lastReceivingDate?.toDate?.() || new Date(lastReceivingDate);
          
        return isValid(date) && date > new Date();
      } catch (error) {
        console.error('Error parsing date:', error);
        return false;
      }
    })
    .sort((a, b) => {
      try {
        const dateA = parseISO(a.shipmentInfo.lastReceivingDate);
        const dateB = parseISO(b.shipmentInfo.lastReceivingDate);
        return dateA.getTime() - dateB.getTime();
      } catch {
        return 0;
      }
    })
    .slice(0, 5); // Show only next 5 shipments

  const formatDate = (dateString: string | Date | undefined) => {
    if (!dateString) return 'N/A';
    try {
      const date = typeof dateString === 'string' 
        ? parseISO(dateString)
        : dateString instanceof Date 
          ? dateString 
          : dateString?.toDate?.() || new Date(dateString);
          
      return isValid(date) ? format(date, 'MMM d, yyyy') : 'N/A';
    } catch {
      return 'N/A';
    }
  };

  if (upcomingShipments.length === 0) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-lg font-semibold mb-4">Upcoming Shipments</h2>
        <p className="text-gray-500 text-center py-4">No upcoming shipments</p>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-lg font-semibold mb-4">Upcoming Shipments</h2>
      <div className="space-y-4">
        {upcomingShipments.map((shipment) => (
          <div
            key={shipment.id}
            className="flex items-start space-x-4 p-4 border rounded-lg hover:bg-gray-50 transition-colors"
          >
            <div className="p-2 bg-blue-50 rounded-lg">
              <Ship className="h-6 w-6 text-blue-600" />
            </div>
            <div className="flex-grow">
              <h3 className="font-medium text-gray-900">
                {shipment.shippingDetails.vessel || 'Vessel TBD'}
              </h3>
              <p className="text-sm text-gray-500">{shipment.shippingDetails.destination}</p>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <Calendar className="h-4 w-4 mr-1" />
                {formatDate(shipment.shipmentInfo.lastReceivingDate)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UpcomingShipments;