import React, { useEffect, useState } from 'react';
import { ArrowLeft, Truck, MapPin, Calendar } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import { useShipmentStore } from '../stores/shipmentStore';
import StatusBadge from './StatusBadge';
import TruckingForm from './TruckingForm';

const TruckingShipmentDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getShipment } = useShipmentStore();
  const [shipment, setShipment] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadShipment = async () => {
      if (!id) return;
      
      try {
        setIsLoading(true);
        const data = await getShipment(id);
        if (data) {
          setShipment(data);
        } else {
          setError('Shipment not found');
        }
      } catch (err) {
        setError('Error loading shipment');
        console.error('Error loading shipment:', err);
      } finally {
        setIsLoading(false);
      }
    };

    loadShipment();
  }, [id, getShipment]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error || !shipment) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="text-center text-red-600">
          {error || 'Shipment not found'}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <button
            onClick={() => navigate('/shipments')}
            className="mr-4 p-2 hover:bg-gray-100 rounded-full"
          >
            <ArrowLeft className="h-5 w-5" />
          </button>
          <div>
            <div className="flex items-center">
              <Truck className="h-6 w-6 text-blue-600 mr-2" />
              <h2 className="text-2xl font-bold">Trucking Details</h2>
            </div>
            <p className="text-sm text-gray-500">Reference: {shipment.referenceNumber}</p>
          </div>
        </div>
        <StatusBadge status={shipment.truckingStatus || 'pending'} />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Shipment Information</h3>
          <div className="grid grid-cols-2 gap-4">
            <InfoItem label="Container Number" value={shipment.containerNumber || 'Not assigned'} />
            <InfoItem label="Seal Number" value={shipment.sealNumber || 'Not assigned'} />
            <InfoItem label="Container Type" value={shipment.shippingDetails.containerInfo} />
            <InfoItem 
              label="Gross Weight" 
              value={`${shipment.shipmentInfo.grossWeight.value} ${shipment.shipmentInfo.grossWeight.unit}`} 
            />
            <InfoItem 
              label="Volume" 
              value={`${shipment.shipmentInfo.volume.value} ${shipment.shipmentInfo.volume.unit}`} 
            />
            <InfoItem label="Dangerous Goods" value={shipment.dangerousGoods ? 'Yes' : 'No'} />
          </div>

          <h3 className="text-lg font-medium text-gray-900 mt-6 mb-4">Route Information</h3>
          <div className="grid grid-cols-2 gap-4">
            <InfoItem label="Pickup Location" value={shipment.pickupLocation || 'Port of San Antonio'} />
            <InfoItem label="Delivery Location" value={shipment.deliveryLocation || shipment.shippingDetails.destination} />
            <InfoItem label="Pickup Date" value={shipment.pickupDate || 'Not set'} />
            <InfoItem label="Estimated Duration" value={shipment.estimatedDuration || '2 hours'} />
          </div>
        </div>

        <div>
          <TruckingForm
            shipmentId={shipment.id}
            initialData={shipment}
            onSuccess={() => {
              getShipment(shipment.id).then(setShipment);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const InfoItem = ({ label, value }: { label: string; value: string }) => (
  <div>
    <h4 className="text-sm font-medium text-gray-500">{label}</h4>
    <p className="mt-1 text-sm text-gray-900">{value}</p>
  </div>
);

export default TruckingShipmentDetails;