import React, { useEffect, useState } from 'react';
import { Search, Filter, Loader2, Ship, AlertCircle, Truck } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../stores/authStore';
import { useShipmentStore } from '../stores/shipmentStore';
import ShipmentList from './ShipmentList';
import { format, parseISO, differenceInDays } from 'date-fns';
import { ShipmentStatus } from '../types/shipment';

const TruckingPortal = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const user = useAuthStore((state) => state.user);
  const { shipments, fetchShipments } = useShipmentStore();

  useEffect(() => {
    const loadData = async () => {
      if (user?.id) {
        try {
          setIsLoading(true);
          await fetchShipments(user.id);
        } catch (error) {
          console.error('Error fetching shipments:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    loadData();
  }, [user, fetchShipments]);

  const handleViewShipment = (id: string) => {
    navigate(`/shipments/${id}`);
  };

  // Filter only departed shipments
  const departedShipments = shipments.filter(s => 
    s.status === ShipmentStatus.DEPARTED
  );

  // Get shipments arriving soon (within next 7 days)
  const arrivingSoon = departedShipments.filter(s => {
    if (!s.estimatedArrival) return false;
    const daysUntilArrival = differenceInDays(parseISO(s.estimatedArrival), new Date());
    return daysUntilArrival >= 0 && daysUntilArrival <= 7;
  });

  // Other shipments that haven't departed yet
  const otherShipments = shipments.filter(s => 
    s.status !== ShipmentStatus.DEPARTED
  );

  if (!user) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6">
        <p className="text-center text-gray-600">Please log in to access this page.</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-center h-32">
          <Loader2 className="h-8 w-8 text-blue-600 animate-spin" />
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Arriving Soon Alert */}
      {arrivingSoon.length > 0 && (
        <div className="bg-blue-50 border-l-4 border-blue-400 p-4">
          <div className="flex">
            <Ship className="h-5 w-5 text-blue-400 mr-2 mt-0.5" />
            <div>
              <p className="text-sm text-blue-700 font-medium">Arriving Soon</p>
              <div className="mt-2 space-y-2">
                {arrivingSoon.map(shipment => (
                  <div key={shipment.id} className="text-sm text-blue-600">
                    <p>MBL: {shipment.mblNumber || 'N/A'} | Container: {shipment.containerNumber || 'N/A'}</p>
                    <p className="ml-4">
                      ETA: {format(parseISO(shipment.estimatedArrival), 'MMM d, yyyy')} | 
                      Weight: {shipment.shipmentInfo.grossWeight.value} {shipment.shipmentInfo.grossWeight.unit}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Active Shipments Section */}
      <div className="bg-white rounded-lg shadow-lg">
        <div className="p-6 border-b">
          <div className="flex items-center space-x-2 mb-4">
            <Ship className="h-6 w-6 text-blue-600" />
            <h1 className="text-2xl font-bold text-gray-900">Active Shipments</h1>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search by MBL, container..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-4 py-2 border rounded-md pl-10"
              />
              <Search className="h-5 w-5 text-gray-400 absolute left-3 top-2.5" />
            </div>

            <div className="flex items-center space-x-2">
              <Filter className="h-5 w-5 text-gray-400" />
              <select
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
                className="block w-full px-3 py-2 border rounded-md"
              >
                <option value="all">All Status</option>
                <option value="departed">Departed</option>
                <option value="delivered">Delivered</option>
              </select>
            </div>
          </div>
        </div>

        <ShipmentList 
          shipments={departedShipments}
          searchTerm={searchTerm}
          filterStatus={filterStatus}
          onView={handleViewShipment}
        />
      </div>

      {/* Other Shipments Section */}
      <div className="bg-white rounded-lg shadow-lg">
        <div className="p-6 border-b">
          <div className="flex items-center space-x-2">
            <AlertCircle className="h-6 w-6 text-gray-400" />
            <h2 className="text-xl font-bold text-gray-900">Other Shipments</h2>
          </div>
        </div>

        <ShipmentList 
          shipments={otherShipments}
          searchTerm={searchTerm}
          filterStatus={filterStatus}
          onView={handleViewShipment}
        />
      </div>
    </div>
  );
};

export default TruckingPortal;