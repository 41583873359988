import { initializeApp } from 'firebase/app';
import { 
  getFirestore, 
  enableIndexedDbPersistence, 
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
  enableMultiTabIndexedDbPersistence
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore with settings for better offline support
const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  experimentalAutoDetectLongPolling: true, // Use auto-detection instead of forcing
  ignoreUndefinedProperties: true
});

// Initialize Storage
const storage = getStorage(app);

// Enable offline persistence with multi-tab support
const enableOfflineSupport = async () => {
  try {
    await enableMultiTabIndexedDbPersistence(db);
    console.log('Offline persistence enabled with multi-tab support');
  } catch (err: any) {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled in one tab at a time
      try {
        // Fallback to single-tab persistence
        await enableIndexedDbPersistence(db);
        console.log('Offline persistence enabled in single-tab mode');
      } catch (fallbackErr: any) {
        console.warn('Failed to enable offline persistence:', fallbackErr);
      }
    } else if (err.code === 'unimplemented') {
      console.warn('The current browser does not support offline persistence');
    } else {
      console.error('Error enabling offline persistence:', err);
    }
  }
};

// Initialize offline support
enableOfflineSupport().catch(console.error);

// Add connection state listener
if (typeof window !== 'undefined') {
  window.addEventListener('online', () => {
    console.log('Connection restored. Syncing data...');
  });

  window.addEventListener('offline', () => {
    console.log('Connection lost. Operating in offline mode...');
  });
}

export { db, storage, app };