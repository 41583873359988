import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Eye, Calendar, FileText, Ship, Info, CheckCircle } from 'lucide-react';
import { format, parseISO } from 'date-fns';
import StatusBadge from './StatusBadge';
import { ShipmentStatus } from '../types/shipment';
import SLISubmitButton from './sli/SLISubmitButton';

interface ShipmentListProps {
  shipments: any[];
  searchTerm: string;
  filterStatus?: string;
  onView?: (id: string) => void;
  onEnterBookingDetails?: (shipment: any) => void;
  onSetPlannedLoading?: (shipment: any) => void;
  onCompleteLoading?: (shipment: any) => void;
  onConfirmLoadingDate?: (shipment: any) => void;
  isCustomer?: boolean;
  isAdmin?: boolean;
}

const ShipmentList = ({ 
  shipments = [], 
  searchTerm = '', 
  filterStatus = 'all',
  onView,
  onEnterBookingDetails,
  onSetPlannedLoading,
  onCompleteLoading,
  onConfirmLoadingDate,
  isCustomer = false,
  isAdmin = false
}: ShipmentListProps) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const toggleRow = (id: string) => {
    setExpandedRows(prev => 
      prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
    );
  };

  const renderActions = (shipment: any) => {
    const actions = [];

    if (onView) {
      actions.push(
        <button 
          key="view"
          onClick={() => onView(shipment.id)}
          className="inline-flex items-center px-3 py-2 text-sm font-medium text-blue-600 hover:text-blue-800"
          title="View Details"
        >
          <Eye className="h-4 w-4 mr-2" />
          View Details
        </button>
      );
    }

    if (isAdmin) {
      if (shipment.status === ShipmentStatus.BOOKING_CONFIRMED && onEnterBookingDetails) {
        actions.push(
          <button
            key="booking-details"
            onClick={() => onEnterBookingDetails(shipment)}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-green-600 hover:text-green-800"
            title="Enter Booking Details"
          >
            <Ship className="h-4 w-4 mr-2" />
            Enter Booking Details
          </button>
        );
      }
    }

    if (isCustomer) {
      if (shipment.status === ShipmentStatus.WAITING_FOR_SLI) {
        actions.push(
          <div key="submit-sli" className="inline-flex items-center px-3 py-2">
            <SLISubmitButton 
              shipment={shipment}
              onSuccess={() => {
                // Refresh the list if needed
              }}
            />
          </div>
        );
      }

      if (shipment.status === ShipmentStatus.WAITING_FOR_LOADING && !shipment.loadingDateSet && onSetPlannedLoading) {
        actions.push(
          <button
            key="set-loading"
            onClick={() => onSetPlannedLoading(shipment)}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-purple-600 hover:text-purple-800"
            title="Set Planned Loading Date"
          >
            <Calendar className="h-4 w-4 mr-2" />
            Set Planned Loading
          </button>
        );
      }

      if (shipment.status === ShipmentStatus.LOADING_CONFIRMED && onCompleteLoading) {
        actions.push(
          <button
            key="complete-loading"
            onClick={() => onCompleteLoading(shipment)}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-green-600 hover:text-green-800"
            title="Complete Loading"
          >
            <CheckCircle className="h-4 w-4 mr-2" />
            Complete Loading
          </button>
        );
      }
    }

    return (
      <div className="flex items-center space-x-2">
        {actions}
      </div>
    );
  };

  // Filter shipments based on search term and status
  const filteredShipments = shipments.filter(shipment => {
    const matchesSearch = 
      (shipment.referenceNumber?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
      (shipment.bookingNumber?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
      (shipment.shipmentInfo?.customerPO?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
      (shipment.scmReference?.toLowerCase() || '').includes(searchTerm.toLowerCase());

    const matchesFilter = filterStatus === 'all' || shipment.status === filterStatus;

    return matchesSearch && matchesFilter;
  });

  if (filteredShipments.length === 0) {
    return (
      <div className="p-8 text-center text-gray-500">
        No shipments found matching your criteria
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="w-10"></th>
            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Reference #
            </th>
            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              SCM Ref #
            </th>
            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              PO#
            </th>
            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Container
            </th>
            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Vessel
            </th>
            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              ETD
            </th>
            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Port
            </th>
            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Status
            </th>
            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {filteredShipments.map((shipment) => (
            <React.Fragment key={shipment.id}>
              <tr className="hover:bg-gray-50">
                <td className="px-3 py-4">
                  <button
                    onClick={() => toggleRow(shipment.id)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    {expandedRows.includes(shipment.id) ? (
                      <ChevronUp className="h-5 w-5" />
                    ) : (
                      <ChevronDown className="h-5 w-5" />
                    )}
                  </button>
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {shipment.referenceNumber}
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  {shipment.scmReference || '-'}
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  {shipment.shipmentInfo?.customerPO}
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  {shipment.shipmentInfo?.containerQuantity}x {shipment.shipmentInfo?.containerType}
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  {shipment.vessel || '-'}
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  {shipment.etd ? format(parseISO(shipment.etd), 'MMM d, yyyy') : '-'}
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  {shipment.shippingDetails?.portOfLoading || '-'}
                </td>
                <td className="px-3 py-4 whitespace-nowrap">
                  <StatusBadge status={shipment.status} />
                </td>
                <td className="px-3 py-4 whitespace-nowrap">
                  {renderActions(shipment)}
                </td>
              </tr>
              {expandedRows.includes(shipment.id) && (
                <tr>
                  <td colSpan={10} className="px-3 py-4 bg-gray-50">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <h4 className="font-medium text-gray-900">Shipping Details</h4>
                        <dl className="mt-2 text-sm">
                          <div className="grid grid-cols-2 gap-2">
                            <dt className="text-gray-500">Port of Loading:</dt>
                            <dd className="text-gray-900">{shipment.shippingDetails?.portOfLoading}</dd>
                            <dt className="text-gray-500">Port of Discharge:</dt>
                            <dd className="text-gray-900">{shipment.shippingDetails?.portOfDischarge}</dd>
                            {shipment.vessel && (
                              <>
                                <dt className="text-gray-500">Vessel:</dt>
                                <dd className="text-gray-900">{shipment.vessel}</dd>
                              </>
                            )}
                            {shipment.eta && (
                              <>
                                <dt className="text-gray-500">ETA:</dt>
                                <dd className="text-gray-900">{format(parseISO(shipment.eta), 'MMM d, yyyy')}</dd>
                              </>
                            )}
                            {shipment.bookingNumber && (
                              <>
                                <dt className="text-gray-500">Booking #:</dt>
                                <dd className="text-gray-900">{shipment.bookingNumber}</dd>
                              </>
                            )}
                            {shipment.mblNumber && (
                              <>
                                <dt className="text-gray-500">MBL #:</dt>
                                <dd className="text-gray-900">{shipment.mblNumber}</dd>
                              </>
                            )}
                          </div>
                        </dl>
                      </div>
                      <div>
                        <h4 className="font-medium text-gray-900">Cargo Details</h4>
                        <dl className="mt-2 text-sm">
                          <div className="grid grid-cols-2 gap-2">
                            <dt className="text-gray-500">Weight:</dt>
                            <dd className="text-gray-900">
                              {shipment.shipmentInfo?.weight} {shipment.shipmentInfo?.weightUnit}
                            </dd>
                            <dt className="text-gray-500">Volume:</dt>
                            <dd className="text-gray-900">
                              {shipment.shipmentInfo?.volume} {shipment.shipmentInfo?.volumeUnit}
                            </dd>
                            <dt className="text-gray-500">Container Qty:</dt>
                            <dd className="text-gray-900">{shipment.shipmentInfo?.containerQuantity}</dd>
                            <dt className="text-gray-500">Commodity:</dt>
                            <dd className="text-gray-900">{shipment.shipmentInfo?.commodity || '-'}</dd>
                            <dt className="text-gray-500">HS Code:</dt>
                            <dd className="text-gray-900">{shipment.shipmentInfo?.hsCode || '-'}</dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ShipmentList;