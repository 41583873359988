import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Save, Loader2, X, Plus, History } from 'lucide-react';
import { useAuthStore } from '../../stores/authStore';
import { useShipmentStore } from '../../stores/shipmentStore';
import { ShipmentStatus } from '../../types/shipment';
import { bookingSchema } from '../../types/shipment';
import type { BookingFormData, BookingFormProps } from './types';
import ReferenceSection from './ReferenceSection';
import ShipperSection from './ShipperSection';
import CargoSection from './CargoSection';
import ShippingSection from './ShippingSection';
import AdditionalSection from './AdditionalSection';
import PastShipmentsSelector from './PastShipmentsSelector';
import { PRESET_SHIPPERS } from '../../data/shippers';

const BookingRequestForm = ({ onClose, onSuccess }: BookingFormProps) => {
  const { user } = useAuthStore();
  const { addShipment } = useShipmentStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedShipper, setSelectedShipper] = useState('sunsweet');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [showPastShipments, setShowPastShipments] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors }
  } = useForm<BookingFormData>({
    resolver: zodResolver(bookingSchema),
    defaultValues: {
      weightUnit: 'KG',
      volumeUnit: 'M3',
      requiredDocuments: {
        phytosanitary: false,
        agro: false,
        certificateOfOrigin: false
      },
      shipper: PRESET_SHIPPERS[0]
    }
  });

  const handleShipperSelect = (shipperId: string) => {
    setSelectedShipper(shipperId);
    const shipper = PRESET_SHIPPERS.find(s => s.id === shipperId);
    if (shipper) {
      setValue('shipper', shipper);
    }
  };

  const handleCountryChange = (country: string) => {
    setSelectedCountry(country);
    setValue('destinationCountry', country);
  };

  const handlePastShipmentSelect = (data: Partial<BookingFormData>) => {
    Object.entries(data).forEach(([key, value]) => {
      if (value !== undefined) {
        setValue(key as keyof BookingFormData, value);
      }
    });
    if (data.destinationCountry) {
      setSelectedCountry(data.destinationCountry);
    }
  };

  const onSubmit = async (data: BookingFormData) => {
    if (!user) return;

    setIsSubmitting(true);
    setError(null);

    try {
      const bookingData = {
        userId: user.id,
        status: ShipmentStatus.BOOKING_REQUESTED,
        referenceNumber: data.referenceNumber,
        shipmentInfo: {
          customerPO: data.customerPO,
          contractRef: data.contractRef,
          commodity: data.commodity,
          hsCode: data.hsCode,
          sailingWeek: data.sailingWeek,
          containerType: data.containerType,
          containerQuantity: data.containerQuantity,
          weight: data.weight,
          weightUnit: data.weightUnit,
          volume: data.volume,
          volumeUnit: data.volumeUnit,
          shipper: data.shipper,
          requiredDocuments: data.requiredDocuments
        },
        shippingDetails: {
          portOfLoading: data.portOfLoading,
          portOfDischarge: data.portOfDischarge,
          oceanFreight: data.oceanFreight,
          blType: data.blType
        },
        requiresTrucking: data.requiresTrucking || false,
        warehouseName: data.warehouseName,
        specialRequirements: data.specialRequirements,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      await addShipment(bookingData);
      onSuccess?.();
      onClose();
    } catch (error: any) {
      console.error('Error creating booking request:', error);
      setError(error.message || 'Failed to create booking request');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6 border-b border-gray-200 flex justify-between items-center sticky top-0 bg-white z-10">
          <h2 className="text-xl font-bold text-gray-900">New Booking Request</h2>
          <div className="flex items-center space-x-4">
            <button
              type="button"
              onClick={() => setShowPastShipments(true)}
              className="inline-flex items-center px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              <History className="h-4 w-4 mr-2" />
              Import from Past
            </button>
            <button
              type="button"
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <X className="h-6 w-6" />
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-8">
          <ReferenceSection register={register} errors={errors} />
          
          <ShipperSection 
            register={register} 
            errors={errors} 
            selectedShipper={selectedShipper}
            onShipperSelect={handleShipperSelect}
          />
          
          <CargoSection register={register} errors={errors} />
          
          <ShippingSection 
            register={register} 
            errors={errors}
            selectedCountry={selectedCountry}
            onCountryChange={handleCountryChange}
          />
          
          <AdditionalSection register={register} errors={errors} />

          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <p className="text-sm text-red-600">{error}</p>
            </div>
          )}

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {isSubmitting ? (
                <>
                  <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                  Submitting...
                </>
              ) : (
                <>
                  <Plus className="h-5 w-5 mr-2" />
                  Submit Request
                </>
              )}
            </button>
          </div>
        </form>
      </div>

      {showPastShipments && (
        <PastShipmentsSelector
          onSelect={handlePastShipmentSelect}
          onClose={() => setShowPastShipments(false)}
        />
      )}
    </div>
  );
};

export default BookingRequestForm;