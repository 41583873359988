import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import type { User } from '../types/auth';

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  setUser: (user: User | null) => void;
  setError: (error: string | null) => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      isAuthenticated: false,
      isLoading: false,
      error: null,

      login: async (email: string, password: string) => {
        try {
          set({ isLoading: true, error: null });

          // Demo accounts
          if (email === 'admin@logistics.com' && password === 'admin123') {
            const adminUser: User = {
              id: 'admin-user',
              email: 'admin@logistics.com',
              name: 'Admin',
              role: 'admin',
              companyId: 'admin'
            };
            set({
              user: adminUser,
              isAuthenticated: true,
              error: null
            });
            return;
          }

          if (email === 'customer@logistics.com' && password === 'customer123') {
            const customerUser: User = {
              id: 'customer-user',
              email: 'customer@logistics.com',
              name: 'Customer',
              role: 'customer',
              companyId: 'customer'
            };
            set({
              user: customerUser,
              isAuthenticated: true,
              error: null
            });
            return;
          }

          if (email === 'trucker@logistics.com' && password === 'trucker123') {
            const truckerUser: User = {
              id: 'trucker-user',
              email: 'trucker@logistics.com',
              name: 'Trucker',
              role: 'trucker',
              companyId: 'trucker'
            };
            set({
              user: truckerUser,
              isAuthenticated: true,
              error: null
            });
            return;
          }

          throw new Error('Invalid credentials');
        } catch (error: any) {
          console.error('Login error:', error);
          set({ 
            error: error.message || 'Invalid email or password',
            isAuthenticated: false,
            user: null 
          });
          throw error;
        } finally {
          set({ isLoading: false });
        }
      },

      logout: async () => {
        try {
          // Clear all stored data
          localStorage.clear();
          sessionStorage.clear();
          set({ 
            user: null, 
            isAuthenticated: false,
            error: null
          });
        } catch (error) {
          console.error('Logout error:', error);
          set({ error: 'Error signing out' });
        }
      },

      setUser: (user) => set({ 
        user, 
        isAuthenticated: !!user,
        error: null
      }),
      
      setError: (error) => set({ error })
    }),
    {
      name: 'auth-storage',
      storage: createJSONStorage(() => ({
        getItem: (name) => {
          const str = localStorage.getItem(name);
          if (!str) return null;
          try {
            const data = JSON.parse(str);
            // Validate stored data
            if (data && data.state && data.state.user && data.state.isAuthenticated) {
              return str;
            }
            return null;
          } catch {
            return null;
          }
        },
        setItem: (name, value) => {
          localStorage.setItem(name, value);
          // Also store in session storage as backup
          sessionStorage.setItem(name, value);
        },
        removeItem: (name) => {
          localStorage.removeItem(name);
          sessionStorage.removeItem(name);
        }
      })),
      version: 3,
      partialize: (state) => ({
        user: state.user,
        isAuthenticated: state.isAuthenticated
      }),
      onRehydrateStorage: () => (state) => {
        // Validate stored state on rehydration
        if (state && (!state.user || !state.isAuthenticated)) {
          state?.logout();
        }
      }
    }
  )
);