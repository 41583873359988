import React, { useState } from 'react';
import { Search, History, Copy } from 'lucide-react';
import { format, parseISO } from 'date-fns';
import { useShipmentStore } from '../../stores/shipmentStore';

interface PastShipmentsProps {
  onSelect: (shipment: any) => void;
  onClose: () => void;
}

const PastShipmentsSelector = ({ onSelect, onClose }: PastShipmentsProps) => {
  const { shipments } = useShipmentStore();
  const [searchTerm, setSearchTerm] = useState('');

  const pastShipments = shipments
    .filter(shipment => 
      (shipment.referenceNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
       shipment.shipmentInfo?.customerPO?.toLowerCase().includes(searchTerm.toLowerCase()) ||
       shipment.shipmentInfo?.commodity?.toLowerCase().includes(searchTerm.toLowerCase())) &&
      shipment.status !== 'booking_requested'
    )
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

  const handleSelect = (shipment: any) => {
    // Extract relevant data for new booking
    const bookingData = {
      customerPO: shipment.shipmentInfo?.customerPO,
      destination: shipment.shipmentInfo?.destinationCountry,
      portOfDischarge: shipment.shipmentInfo?.destinationPort,
      containerType: shipment.shipmentInfo?.containerType,
      cargoDescription: shipment.shipmentInfo?.commodity,
      quantity: shipment.shipmentInfo?.quantity,
      quantityType: shipment.shipmentInfo?.quantityType,
      pallets: shipment.shipmentInfo?.pallets,
      weight: shipment.shipmentInfo?.weight,
      weightUnit: shipment.shipmentInfo?.weightUnit,
      volume: shipment.shipmentInfo?.volume,
      volumeUnit: shipment.shipmentInfo?.volumeUnit,
      consignee: shipment.shipmentInfo?.consignee,
      specialRequirements: shipment.shipmentInfo?.specialRequirements,
      incoterms: shipment.shipmentInfo?.incoterms,
      blType: shipment.shipmentInfo?.blType
    };

    onSelect(bookingData);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center space-x-2">
            <History className="h-6 w-6 text-blue-600" />
            <h2 className="text-xl font-bold text-gray-900">Import from Past Shipments</h2>
          </div>
          
          <div className="mt-4 relative">
            <input
              type="text"
              placeholder="Search by reference number, PO, or commodity..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 py-2 pl-10 border rounded-md"
            />
            <Search className="h-5 w-5 text-gray-400 absolute left-3 top-2.5" />
          </div>
        </div>

        <div className="max-h-[60vh] overflow-y-auto p-6">
          {pastShipments.length === 0 ? (
            <p className="text-center text-gray-500">No past shipments found</p>
          ) : (
            <div className="space-y-4">
              {pastShipments.map((shipment) => (
                <div
                  key={shipment.id}
                  className="border rounded-lg p-4 hover:bg-blue-50 cursor-pointer transition-colors"
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="font-medium text-gray-900">
                        {shipment.referenceNumber}
                      </h3>
                      <div className="mt-1 space-y-1 text-sm text-gray-500">
                        <p>PO: {shipment.shipmentInfo?.customerPO}</p>
                        <p>SSL Contract #: {shipment.shipmentInfo?.contractRef}</p>
                        <p>Commodity: {shipment.shipmentInfo?.commodity}</p>
                        <p>Container: {shipment.shipmentInfo?.containerType}</p>
                        <p>Destination: {shipment.shipmentInfo?.destinationPort}</p>
                        <p>Created: {format(parseISO(shipment.createdAt), 'MMM d, yyyy')}</p>
                      </div>
                    </div>
                    <button
                      onClick={() => handleSelect(shipment)}
                      className="flex items-center space-x-1 text-blue-600 hover:text-blue-800"
                    >
                      <Copy className="h-4 w-4" />
                      <span>Use</span>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="p-6 border-t border-gray-200 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PastShipmentsSelector;