import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, FileSpreadsheet, Loader2, X, AlertCircle, CheckCircle2 } from 'lucide-react';
import * as XLSX from 'xlsx';
import { useShipmentStore } from '../stores/shipmentStore';
import { useAuthStore } from '../stores/authStore';
import { ShipmentStatus } from '../types/shipment';

interface BulkBookingUploadProps {
  onClose: () => void;
  onSuccess: () => void;
}

interface BookingData {
  referenceNumber: string;
  customerPO: string;
  destination: string;
  portOfDischarge: string;
  containerInfo: string;
  desiredShippingDate: string;
  cargoDescription: string;
  quantity: number;
  quantityType: 'cases' | 'packages' | 'bundles';
  pallets?: number;
  weight: number;
  weightUnit: 'KG' | 'LB';
  volume: number;
  volumeUnit: 'M3' | 'FT3';
  requiresTrucking?: boolean;
  warehouseName?: string;
  specialRequirements?: string;
  consigneeName: string;
  consigneeAddress: string;
  consigneeCity: string;
  consigneeCountry: string;
  consigneeTaxId: string;
  consigneeZipCode?: string;
}

const BulkBookingUpload = ({ onClose, onSuccess }: BulkBookingUploadProps) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successCount, setSuccessCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const [preview, setPreview] = useState<BookingData[]>([]);
  const { addShipment } = useShipmentStore();
  const { user } = useAuthStore();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls']
    },
    maxFiles: 1,
    disabled: isProcessing,
    onDrop: async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) return;

      try {
        const data = await readExcelFile(file);
        setPreview(data);
        setError(null);
      } catch (error) {
        console.error('Error reading Excel file:', error);
        setError('Failed to read Excel file. Please check the format.');
      }
    }
  });

  const readExcelFile = (file: File): Promise<BookingData[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = e.target?.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet) as BookingData[];

          // Validate and transform data
          const validatedData = jsonData.map(row => ({
            referenceNumber: row.referenceNumber?.toString() || '',
            customerPO: row.customerPO?.toString() || '',
            destination: row.destination?.toString() || '',
            portOfDischarge: row.portOfDischarge?.toString() || '',
            containerInfo: row.containerInfo?.toString() || '',
            desiredShippingDate: row.desiredShippingDate?.toString() || '',
            cargoDescription: row.cargoDescription?.toString() || '',
            quantity: Number(row.quantity) || 0,
            quantityType: (row.quantityType || 'cases') as 'cases' | 'packages' | 'bundles',
            pallets: Number(row.pallets) || undefined,
            weight: Number(row.weight) || 0,
            weightUnit: (row.weightUnit === 'LB' ? 'LB' : 'KG') as 'KG' | 'LB',
            volume: Number(row.volume) || 0,
            volumeUnit: (row.volumeUnit === 'FT3' ? 'FT3' : 'M3') as 'M3' | 'FT3',
            requiresTrucking: Boolean(row.requiresTrucking),
            warehouseName: row.warehouseName?.toString() || '',
            specialRequirements: row.specialRequirements?.toString() || '',
            consigneeName: row.consigneeName?.toString() || '',
            consigneeAddress: row.consigneeAddress?.toString() || '',
            consigneeCity: row.consigneeCity?.toString() || '',
            consigneeCountry: row.consigneeCountry?.toString() || '',
            consigneeTaxId: row.consigneeTaxId?.toString() || '',
            consigneeZipCode: row.consigneeZipCode?.toString()
          }));

          resolve(validatedData);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsBinaryString(file);
    });
  };

  const handleUpload = async () => {
    if (!user || !preview.length) return;

    setIsProcessing(true);
    setSuccessCount(0);
    setFailedCount(0);

    try {
      for (const booking of preview) {
        try {
          await addShipment({
            userId: user.id,
            referenceNumber: booking.referenceNumber,
            shipmentInfo: {
              customerPO: booking.customerPO,
              documentDate: new Date().toISOString().split('T')[0],
              lastReceivingDate: booking.desiredShippingDate,
              cargoDescription: booking.cargoDescription,
              quantity: booking.quantity,
              quantityType: booking.quantityType,
              pallets: booking.pallets,
              grossWeight: {
                value: booking.weight,
                unit: booking.weightUnit
              },
              volume: {
                value: booking.volume,
                unit: booking.volumeUnit
              },
              consignee: {
                name: booking.consigneeName,
                address: booking.consigneeAddress,
                city: booking.consigneeCity,
                country: booking.consigneeCountry,
                taxId: booking.consigneeTaxId,
                zipCode: booking.consigneeZipCode
              }
            },
            shippingDetails: {
              destination: booking.destination,
              portOfDischarge: booking.portOfDischarge,
              containerInfo: booking.containerInfo
            },
            requiresTrucking: booking.requiresTrucking,
            warehouseName: booking.warehouseName,
            specialRequirements: booking.specialRequirements
          });
          setSuccessCount(prev => prev + 1);
        } catch (error) {
          console.error('Error adding booking:', error);
          setFailedCount(prev => prev + 1);
        }
      }

      if (successCount === preview.length) {
        onSuccess();
        onClose();
      }
    } catch (error) {
      console.error('Error processing bookings:', error);
      setError('Failed to process some bookings. Please check the data and try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const downloadTemplate = () => {
    const template = XLSX.utils.book_new();
    const data = [{
      referenceNumber: 'REF001',
      customerPO: 'PO123',
      destination: 'United States',
      portOfDischarge: 'USLAX',
      containerInfo: '1x40',
      desiredShippingDate: '2024-04-01',
      cargoDescription: 'Sample cargo',
      quantity: 100,
      quantityType: 'cases',
      pallets: 5,
      weight: 1000,
      weightUnit: 'KG',
      volume: 20,
      volumeUnit: 'M3',
      requiresTrucking: false,
      warehouseName: '',
      specialRequirements: '',
      consigneeName: 'Sample Company',
      consigneeAddress: '123 Main St',
      consigneeCity: 'Los Angeles',
      consigneeCountry: 'United States',
      consigneeTaxId: 'TAX123',
      consigneeZipCode: '90001'
    }];

    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(template, ws, 'Template');
    XLSX.writeFile(template, 'booking_template.xlsx');
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full">
        <div className="p-6 border-b border-gray-200 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <FileSpreadsheet className="h-6 w-6 text-green-600" />
            <h2 className="text-2xl font-bold">Bulk Booking Upload</h2>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="p-6 space-y-6">
          <div className="flex justify-end">
            <button
              onClick={downloadTemplate}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              <FileSpreadsheet className="h-4 w-4 mr-2" />
              Download Template
            </button>
          </div>

          <div
            {...getRootProps()}
            className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors
              ${isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-blue-400'}
              ${isProcessing ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <input {...getInputProps()} />
            {isProcessing ? (
              <div className="flex flex-col items-center">
                <Loader2 className="h-12 w-12 text-blue-500 animate-spin mb-4" />
                <p className="text-blue-500">Processing file...</p>
              </div>
            ) : isDragActive ? (
              <div className="flex flex-col items-center">
                <Upload className="h-12 w-12 text-blue-500 mb-4" />
                <p className="text-blue-500">Drop the Excel file here</p>
              </div>
            ) : (
              <div className="flex flex-col items-center">
                <Upload className="h-12 w-12 text-gray-400 mb-4" />
                <p className="text-gray-600">Drag and drop an Excel file here, or click to select</p>
                <p className="text-sm text-gray-500 mt-2">Only .xlsx and .xls files are supported</p>
              </div>
            )}
          </div>

          {error && (
            <div className="bg-red-50 border-l-4 border-red-400 p-4">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          )}

          {(successCount > 0 || failedCount > 0) && (
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center space-x-4">
                {successCount > 0 && (
                  <div className="flex items-center text-green-700">
                    <CheckCircle2 className="h-5 w-5 mr-2" />
                    {successCount} bookings created
                  </div>
                )}
                {failedCount > 0 && (
                  <div className="flex items-center text-red-700">
                    <AlertCircle className="h-5 w-5 mr-2" />
                    {failedCount} bookings failed
                  </div>
                )}
              </div>
            </div>
          )}

          {preview.length > 0 && (
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">Preview ({preview.length} bookings)</h3>
              <div className="max-h-64 overflow-y-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Reference
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        PO
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Container
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Destination
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {preview.map((booking, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {booking.referenceNumber}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {booking.customerPO}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {booking.containerInfo}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {booking.destination}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleUpload}
              disabled={isProcessing || !preview.length}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {isProcessing ? (
                <>
                  <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                  Processing...
                </>
              ) : (
                <>
                  <Upload className="h-5 w-5 mr-2" />
                  Upload {preview.length} Bookings
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkBookingUpload;