import { ShipmentInfo, ShippingDetails } from '../types/shipment';
import * as pdfjs from 'pdfjs-dist';

// Set up the PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export class PDFParseError extends Error {
  details: string;

  constructor(message: string, details?: string) {
    super(message);
    this.name = 'PDFParseError';
    this.details = details || 'Please ensure the file is a valid SLI document and try again';
  }
}

export interface ParsedShipment {
  shipmentInfo: ShipmentInfo;
  shippingDetails: ShippingDetails;
}

export async function parsePDF(file: File): Promise<ParsedShipment> {
  try {
    const text = await extractTextFromPDF(file);
    return extractDataFromText(text);
  } catch (error) {
    console.error('Error parsing PDF:', error);
    if (error instanceof PDFParseError) {
      throw error;
    }
    throw new PDFParseError(
      'Failed to parse PDF file',
      'The PDF file appears to be corrupted or in an unsupported format'
    );
  }
}

async function extractTextFromPDF(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const arrayBuffer = e.target?.result as ArrayBuffer;
        const loadingTask = pdfjs.getDocument({ data: arrayBuffer });
        const pdf = await loadingTask.promise;
        let fullText = '';
        
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const content = await page.getTextContent();
          const pageText = content.items
            .map((item: any) => item.str)
            .join(' ');
          fullText += pageText + '\n';
        }
        
        if (!fullText.trim()) {
          throw new PDFParseError(
            'No text content found',
            'The PDF appears to be empty or contains only images'
          );
        }
        
        resolve(fullText);
      } catch (error) {
        if (error instanceof PDFParseError) {
          reject(error);
        } else {
          reject(new PDFParseError(
            'Failed to read PDF file',
            'Please ensure the file is not corrupted and try again'
          ));
        }
      }
    };
    reader.onerror = () => reject(new PDFParseError('Failed to read file'));
    reader.readAsArrayBuffer(file);
  });
}

function extractDataFromText(text: string): ParsedShipment {
  try {
    // Extract data using regex patterns
    const orderNumber = text.match(/Order Number[:\s]+([^\n\r]+)/i)?.[1]?.trim();
    const vessel = text.match(/Vessel[:\s]+([^\n\r]+)/i)?.[1]?.trim();
    const bookingNo = text.match(/Booking No[:\s]+([^\n\r]+)/i)?.[1]?.trim();
    const destination = text.match(/Destination[:\s]+([^\n\r]+)/i)?.[1]?.trim();
    const customerName = text.match(/Ship-to Party[:\s\n\r]+([^\n\r]+)/i)?.[1]?.trim();
    const documentDate = text.match(/Document Date[:\s]+([^\n\r]+)/i)?.[1]?.trim();
    const lastReceivingDate = text.match(/Last Receiving Date[:\s]+([^\n\r]+)/i)?.[1]?.trim();
    const customerPO = text.match(/Customer P\.O\. #[:\s]+([^\n\r]+)/i)?.[1]?.trim();
    const grossWeight = text.match(/Gross Weight[:\s]+([0-9,.]+)\s*([A-Z]+)/i);
    const netWeight = text.match(/Net Weight[:\s]+([0-9,.]+)\s*([A-Z]+)/i);
    const volume = text.match(/Volume[:\s]+([0-9,.]+)\s*([A-Z]+[0-9]*)/i);

    // Validate required fields
    if (!orderNumber || !customerPO || !customerName) {
      throw new PDFParseError(
        'Missing required information',
        'The PDF must contain Order Number, Customer PO, and Ship-to Party information'
      );
    }

    return {
      shipmentInfo: {
        orderNumber,
        documentDate,
        customerPO,
        lastReceivingDate,
        grossWeight: {
          value: parseFloat(grossWeight?.[1]?.replace(/,/g, '') || '0'),
          unit: grossWeight?.[2] || 'KG'
        },
        netWeight: {
          value: parseFloat(netWeight?.[1]?.replace(/,/g, '') || '0'),
          unit: netWeight?.[2] || 'KG'
        },
        volume: {
          value: parseFloat(volume?.[1]?.replace(/,/g, '') || '0'),
          unit: volume?.[2] || 'M3'
        }
      },
      shippingDetails: {
        vessel: vessel || '',
        destination: destination || '',
        bookingNo: bookingNo || ''
      }
    };
  } catch (error) {
    if (error instanceof PDFParseError) {
      throw error;
    }
    throw new PDFParseError(
      'Failed to extract data from PDF',
      'The PDF structure does not match the expected SLI format'
    );
  }
}