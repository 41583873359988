import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuthStore } from './stores/authStore';
import LoginForm from './components/auth/LoginForm';
import Dashboard from './components/Dashboard';
import CustomerPortal from './components/CustomerPortal';
import AdminPortal from './components/AdminPortal';
import TruckingPortal from './components/TruckingPortal';
import ShipmentDetails from './components/ShipmentDetails';
import AdminShipmentDetails from './components/AdminShipmentDetails';
import TruckingShipmentDetails from './components/TruckingShipmentDetails';
import ProtectedRoute from './components/layout/ProtectedRoute';
import Layout from './components/layout/Layout';
import FirebaseTest from './components/FirebaseTest'; // Add this import

function App() {
  const { isAuthenticated, user, setUser } = useAuthStore();

  useEffect(() => {
    // Check for stored auth data on mount
    const storedAuth = localStorage.getItem('auth-storage');
    if (storedAuth) {
      try {
        const { state } = JSON.parse(storedAuth);
        if (state?.user && state?.isAuthenticated) {
          setUser(state.user);
        }
      } catch (error) {
        console.error('Error restoring auth state:', error);
      }
    }
  }, [setUser]);

  // Redirect to appropriate dashboard based on user role
  const getDashboardRedirect = () => {
    if (!user) return '/login';
    switch (user.role) {
      case 'admin':
        return '/shipments';
      case 'customer':
        return '/customer';
      case 'trucker':
        return '/trucker';
      default:
        return '/dashboard';
    }
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={
          isAuthenticated ? <Navigate to={getDashboardRedirect()} replace /> : <LoginForm />
        } />

        <Route path="/test" element={<FirebaseTest />} /> {/* Add this route */}

        <Route path="/" element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }>
          <Route index element={<Navigate to={getDashboardRedirect()} replace />} />
          <Route path="dashboard" element={<Dashboard />} />
          
          {/* Admin Routes */}
          <Route path="shipments" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <AdminPortal />
            </ProtectedRoute>
          } />
          <Route path="shipments/:id" element={
            <ProtectedRoute allowedRoles={['admin']}>
              <AdminShipmentDetails />
            </ProtectedRoute>
          } />

          {/* Customer Routes */}
          <Route path="customer" element={
            <ProtectedRoute allowedRoles={['customer']}>
              <CustomerPortal />
            </ProtectedRoute>
          } />
          <Route path="customer/shipments/:id" element={
            <ProtectedRoute allowedRoles={['customer']}>
              <ShipmentDetails />
            </ProtectedRoute>
          } />

          {/* Trucker Routes */}
          <Route path="trucker" element={
            <ProtectedRoute allowedRoles={['trucker']}>
              <TruckingPortal />
            </ProtectedRoute>
          } />
          <Route path="trucker/shipments/:id" element={
            <ProtectedRoute allowedRoles={['trucker']}>
              <TruckingShipmentDetails />
            </ProtectedRoute>
          } />
        </Route>

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;