import React, { useState } from 'react';
import { FileText, Download, AlertCircle, Loader2 } from 'lucide-react';
import { format } from 'date-fns';

interface DocumentInfo {
  url: string;
  fileName: string;
  uploadDate: string;
}

interface ShipmentDocumentsProps {
  shipmentId: string;
  documents?: {
    sli?: DocumentInfo;
    mandato?: DocumentInfo;
    proformaInvoice?: DocumentInfo;
    guiaDespacho?: DocumentInfo;
    planillaSag?: DocumentInfo;
    ticketPesaje?: DocumentInfo;
  };
  onDocumentUpdate: (type: string, url: string) => Promise<void>;
  shipment?: any;
}

const ShipmentDocuments = ({ shipmentId, documents = {}, onDocumentUpdate }: ShipmentDocumentsProps) => {
  const [downloadingDoc, setDownloadingDoc] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleDownload = async (url: string, fileName: string, docType: string) => {
    if (!url) {
      setError('Document URL is not available');
      return;
    }

    setDownloadingDoc(docType);
    setError(null);

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to download document: ${response.statusText}`);
      }

      const contentType = response.headers.get('content-type');
      if (!contentType?.includes('application/pdf')) {
        throw new Error('Invalid document format. Expected PDF.');
      }

      const blob = await response.blob();
      if (blob.size === 0) {
        throw new Error('Document is empty');
      }

      // Create and click download link
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the URL object
      setTimeout(() => {
        window.URL.revokeObjectURL(downloadUrl);
      }, 100);

    } catch (error: any) {
      console.error('Error downloading document:', error);
      setError(
        error.message || 
        'Failed to download document. Please check your connection and try again.'
      );
    } finally {
      setDownloadingDoc(null);
    }
  };

  const renderDocument = (doc: DocumentInfo | undefined, title: string, docType: string) => {
    if (!doc) return null;

    const isDownloading = downloadingDoc === docType;

    return (
      <div className="flex items-center justify-between p-4 bg-white rounded-lg border">
        <div className="flex items-center">
          <FileText className="h-5 w-5 text-blue-500 mr-3" />
          <div>
            <h4 className="font-medium text-gray-900">{title}</h4>
            <p className="text-sm text-gray-500">
              Uploaded: {format(new Date(doc.uploadDate), 'MMM d, yyyy HH:mm')}
            </p>
          </div>
        </div>
        <button
          onClick={() => handleDownload(doc.url, doc.fileName, docType)}
          disabled={isDownloading}
          className={`p-2 rounded-full transition-colors ${
            isDownloading 
              ? 'bg-gray-100 cursor-not-allowed' 
              : 'text-blue-600 hover:text-blue-800 hover:bg-blue-50'
          }`}
          title={isDownloading ? 'Downloading...' : 'Download'}
          aria-label={`Download ${title}`}
        >
          {isDownloading ? (
            <Loader2 className="h-5 w-5 animate-spin" />
          ) : (
            <Download className="h-5 w-5" />
          )}
        </button>
      </div>
    );
  };

  return (
    <div className="space-y-6">
      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
            <div className="flex-1">
              <p className="text-sm text-red-600">{error}</p>
              <button
                onClick={() => setError(null)}
                className="mt-2 text-sm text-red-700 hover:text-red-800 underline"
              >
                Dismiss
              </button>
            </div>
          </div>
        </div>
      )}

      {/* SLI Document */}
      {documents.sli && (
        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-2">SLI Document</h3>
          {renderDocument(documents.sli, "Shipper's Letter of Instruction", 'sli')}
        </div>
      )}

      {/* Required Documents */}
      {(documents.mandato || documents.proformaInvoice) && (
        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-2">Required Documents</h3>
          <div className="space-y-3">
            {renderDocument(documents.mandato, 'MANDATO', 'mandato')}
            {renderDocument(documents.proformaInvoice, 'PROFORMA Invoice', 'proformaInvoice')}
          </div>
        </div>
      )}

      {/* Loading Documents */}
      {(documents.guiaDespacho || documents.planillaSag || documents.ticketPesaje) && (
        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-2">Loading Documents</h3>
          <div className="space-y-3">
            {renderDocument(documents.guiaDespacho, 'Guía de Despacho', 'guiaDespacho')}
            {renderDocument(documents.planillaSag, 'Planilla SAG', 'planillaSag')}
            {renderDocument(documents.ticketPesaje, 'Ticket de Pesaje', 'ticketPesaje')}
          </div>
        </div>
      )}

      {Object.keys(documents).length === 0 && (
        <div className="text-center text-gray-500 py-8">
          No documents uploaded yet
        </div>
      )}
    </div>
  );
};

export default ShipmentDocuments;