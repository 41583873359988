import React, { useState } from 'react';
import { Upload, Loader2, AlertCircle } from 'lucide-react';
import { useShipmentStore } from '../../stores/shipmentStore';
import { ShipmentStatus } from '../../types/shipment';

interface SLISubmitButtonProps {
  shipment: any;
  onSuccess?: () => void;
}

const SLISubmitButton = ({ shipment, onSuccess }: SLISubmitButtonProps) => {
  const { updateShipment } = useShipmentStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Validate file type and size
    if (file.type !== 'application/pdf') {
      setError('Only PDF files are accepted');
      return;
    }

    if (file.size > 5 * 1024 * 1024) { // 5MB limit
      setError('File size must be less than 5MB');
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      // Create URL for the file
      const fileUrl = URL.createObjectURL(file);
      const fileName = `SLI_${shipment.referenceNumber}_${Date.now()}.pdf`;

      await updateShipment(shipment.id, {
        ...shipment,
        documents: {
          ...shipment.documents,
          sli: {
            url: fileUrl,
            fileName,
            uploadDate: new Date().toISOString()
          }
        },
        sliSubmitted: true,
        sliSubmittedAt: new Date().toISOString(),
        status: ShipmentStatus.WAITING_FOR_LOADING
      });

      onSuccess?.();
    } catch (error) {
      console.error('Error uploading SLI:', error);
      setError('Failed to upload SLI document');
    } finally {
      setIsSubmitting(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="space-y-4">
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept=".pdf"
        className="hidden"
      />

      <button
        onClick={handleClick}
        disabled={isSubmitting}
        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
      >
        {isSubmitting ? (
          <>
            <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
            Uploading...
          </>
        ) : (
          <>
            <Upload className="h-5 w-5 mr-2" />
            Submit SLI
          </>
        )}
      </button>

      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <AlertCircle className="h-5 w-5 text-red-400 mr-2" />
            <p className="text-sm text-red-600">{error}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SLISubmitButton;