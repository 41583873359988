import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useShipmentStore } from '../stores/shipmentStore';
import { format, parseISO, startOfMonth, endOfMonth, eachMonthOfInterval, subMonths } from 'date-fns';

const ShipmentStats = () => {
  const { shipments } = useShipmentStore();

  // Get last 6 months of data
  const today = new Date();
  const sixMonthsAgo = subMonths(today, 5);
  const months = eachMonthOfInterval({ start: sixMonthsAgo, end: today });

  // Create data for chart
  const data = months.map(month => {
    const monthStart = startOfMonth(month);
    const monthEnd = endOfMonth(month);

    const monthlyShipments = shipments.filter(shipment => {
      try {
        // Handle both string dates and Firestore timestamps
        const createdAt = typeof shipment.createdAt === 'string' 
          ? parseISO(shipment.createdAt)
          : shipment.createdAt?.toDate?.() || new Date(shipment.createdAt);
          
        return createdAt >= monthStart && createdAt <= monthEnd;
      } catch (error) {
        console.error('Error parsing date:', error);
        return false;
      }
    });

    return {
      month: format(month, 'MMM'),
      shipments: monthlyShipments.length
    };
  });

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-lg font-semibold mb-4">Shipment Volume</h2>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Bar dataKey="shipments" fill="#3B82F6" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ShipmentStats;