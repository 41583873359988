import { z } from 'zod';

export const CHILE_PORTS = [
  { code: 'CLVAP', name: 'Valparaíso' },
  { code: 'CLSAI', name: 'San Antonio' },
  { code: 'CLIQQ', name: 'Iquique' },
  { code: 'CLARI', name: 'Arica' },
  { code: 'CLCNL', name: 'Coronel' },
  { code: 'CLLQN', name: 'Lirquén' },
  { code: 'CLSVM', name: 'San Vicente' },
  { code: 'CLANT', name: 'Antofagasta' }
] as const;

export const TOP_PORTS = {
  'Chile': CHILE_PORTS,
  'China': [
    { code: 'CNSHA', name: 'Shanghai' },
    { code: 'CNSHE', name: 'Shenzhen' },
    { code: 'CNNBO', name: 'Ningbo' },
    { code: 'CNQIN', name: 'Qingdao' }
  ],
  'United States': [
    { code: 'USLAX', name: 'Los Angeles' },
    { code: 'USLGB', name: 'Long Beach' },
    { code: 'USNYC', name: 'New York/New Jersey' },
    { code: 'USSAV', name: 'Savannah' }
  ],
  'Singapore': [
    { code: 'SGSIN', name: 'Singapore' }
  ],
  'South Korea': [
    { code: 'KRPUS', name: 'Busan' },
    { code: 'KRINC', name: 'Incheon' },
    { code: 'KRUSN', name: 'Ulsan' }
  ],
  'Japan': [
    { code: 'JPTYO', name: 'Tokyo' },
    { code: 'JPYOK', name: 'Yokohama' },
    { code: 'JPNGO', name: 'Nagoya' }
  ],
  'Germany': [
    { code: 'DEHAM', name: 'Hamburg' },
    { code: 'DEBRV', name: 'Bremerhaven' }
  ],
  'Netherlands': [
    { code: 'NLRTM', name: 'Rotterdam' },
    { code: 'NLAMS', name: 'Amsterdam' }
  ],
  'United Kingdom': [
    { code: 'GBFXT', name: 'Felixstowe' },
    { code: 'GBLGP', name: 'London Gateway' },
    { code: 'GBSOU', name: 'Southampton' }
  ],
  'Spain': [
    { code: 'ESALG', name: 'Algeciras' },
    { code: 'ESVLC', name: 'Valencia' },
    { code: 'ESBAR', name: 'Barcelona' }
  ],
  'Italy': [
    { code: 'ITGOA', name: 'Genoa' },
    { code: 'ITGIT', name: 'Gioia Tauro' },
    { code: 'ITNAP', name: 'Naples' }
  ]
} as const;

export const TOP_COUNTRIES = [
  'China',
  'United States',
  'Singapore', 
  'South Korea',
  'Japan',
  'Germany',
  'Netherlands',
  'United Kingdom',
  'Spain',
  'Italy'
] as const;