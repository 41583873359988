import { z } from 'zod';

export enum ShipmentStatus {
  BOOKING_REQUESTED = 'booking_requested',
  BOOKING_CONFIRMED = 'booking_confirmed',
  WAITING_FOR_SLI = 'waiting_for_sli',
  WAITING_FOR_LOADING = 'waiting_for_loading', // Waiting for planned loading date and initial docs (MANDATO + PROFORMA)
  LOADING_CONFIRMED = 'loading_confirmed', // Planned loading date confirmed by admin
  ON_WAY_TO_PORT = 'on_way_to_port', // After actual loading with all docs
  WAITING_FOR_SAILING = 'waiting_for_sailing',
  DEPARTED = 'departed',
  DELIVERED = 'delivered'
}

export interface ShipmentInfo {
  referenceNumber: string;
  customerPO: string;
  contractRef: string;
  commodity: string;
  hsCode: string;
  sailingWeek: string;
  containerType: string;
  containerQuantity: number;
  weight: number;
  weightUnit: 'KG' | 'LB';
  volume: number;
  volumeUnit: 'M3' | 'FT3';
  shipper: ContactInfo;
  requiredDocuments: {
    phytosanitary: boolean;
    agro: boolean;
    certificateOfOrigin: boolean;
  };
}

export interface ContactInfo {
  id?: string;
  name: string;
  taxId: string;
  address: string;
  city: string;
  state?: string;
  country: string;
  zipCode?: string;
  contactPerson?: string;
  email?: string;
  phone?: string;
}

export interface ShippingDetails {
  portOfLoading: string;
  portOfDischarge: string;
  oceanFreight: 'prepaid' | 'collect';
  blType: string;
  vessel?: string;
  bookingNo?: string;
  eta?: string;
  etd?: string;
}

export interface DocumentInfo {
  url: string;
  uploadDate: string;
  fileName: string;
}

export interface Shipment {
  id: string;
  userId: string;
  status: ShipmentStatus;
  referenceNumber: string;
  shipmentInfo: ShipmentInfo;
  shippingDetails: ShippingDetails;
  requiresTrucking?: boolean;
  warehouseName?: string;
  specialRequirements?: string;
  createdAt: string;
  updatedAt: string;
  sliSubmitted?: boolean;
  sliSubmittedAt?: string;
  plannedLoadingDate?: string;
  plannedLoadingTime?: string;
  loadingConfirmed?: boolean;
  loadingConfirmedAt?: string;
  actualLoadingDate?: string;
  actualLoadingTime?: string;
  departureDate?: string;
  departureTime?: string;
  estimatedArrival?: string;
  documents?: {
    sli?: DocumentInfo;
    mandato?: DocumentInfo;
    proformaInvoice?: DocumentInfo;
    guiaDespacho?: DocumentInfo;
    planillaSag?: DocumentInfo;
    ticketPesaje?: DocumentInfo;
  };
}

export const bookingSchema = z.object({
  referenceNumber: z.string().min(1, 'Reference number is required'),
  customerPO: z.string().min(1, 'PO# is required'),
  contractRef: z.string().min(1, 'Contract reference is required'),
  commodity: z.string().min(1, 'Commodity is required'),
  hsCode: z.string().min(1, 'HS Code is required'),
  sailingWeek: z.string().min(1, 'Sailing week is required'),
  containerType: z.string().min(1, 'Container type is required'),
  containerQuantity: z.coerce.number().min(1, 'Container quantity is required'),
  portOfLoading: z.string().min(1, 'Port of loading is required'),
  destinationCountry: z.string().min(1, 'Destination country is required'),
  portOfDischarge: z.string().min(1, 'Port of discharge is required'),
  oceanFreight: z.enum(['prepaid', 'collect']),
  blType: z.string().min(1, 'B/L type is required'),
  weight: z.coerce.number().min(0.1, 'Weight must be greater than 0'),
  weightUnit: z.enum(['KG', 'LB']),
  volume: z.coerce.number().min(0.1, 'Volume must be greater than 0'),
  volumeUnit: z.enum(['M3', 'FT3']),
  requiredDocuments: z.object({
    phytosanitary: z.boolean(),
    agro: z.boolean(),
    certificateOfOrigin: z.boolean()
  }),
  shipper: z.object({
    id: z.string(),
    name: z.string().min(1, 'Shipper name is required'),
    taxId: z.string().min(1, 'Tax ID is required'),
    address: z.string().min(1, 'Address is required'),
    city: z.string().min(1, 'City is required'),
    state: z.string().optional(),
    country: z.string().min(1, 'Country is required'),
    zipCode: z.string().optional(),
    contactPerson: z.string().optional(),
    email: z.string().email().optional().or(z.literal('')),
    phone: z.string().optional()
  }),
  specialRequirements: z.string().optional(),
  requiresTrucking: z.boolean().optional(),
  warehouseName: z.string().optional()
});