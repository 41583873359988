import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { X, AlertCircle } from 'lucide-react';
import { useShipmentStore } from '../stores/shipmentStore';
import { ShipmentStatus } from '../types/shipment';
import BookingInfoBanner from './shared/BookingInfoBanner';
import PDFUploader from './sli/PDFUploader';
import SLIForm from './sli/SLIForm';
import { PDFParseError } from '../utils/pdfParser';

const sliSchema = z.object({
  orderNumber: z.string().min(1, 'Order number is required'),
  customerPO: z.string().min(1, 'Customer PO is required'),
  materialDescription: z.string().min(1, 'Material description is required'),
  quantity: z.coerce.number().min(1, 'Quantity is required'),
  grossWeight: z.coerce.number().min(0.1, 'Gross weight must be greater than 0'),
  netWeight: z.coerce.number().min(0.1, 'Net weight must be greater than 0'),
  volume: z.coerce.number().min(0.1, 'Volume must be greater than 0'),
  shipToParty: z.object({
    name: z.string().min(1, 'Company name is required'),
    taxId: z.string().min(1, 'Tax ID is required'),
    address: z.string().min(1, 'Address is required'),
    city: z.string().min(1, 'City is required'),
    country: z.string().min(1, 'Country is required')
  }),
  notes: z.string().optional()
});

type SLIFormData = z.infer<typeof sliSchema>;

interface SLIConfirmationFormProps {
  shipment: any;
  onClose: () => void;
  onSuccess?: () => void;
}

const SLIConfirmationForm = ({ shipment, onClose, onSuccess }: SLIConfirmationFormProps) => {
  const { updateShipment } = useShipmentStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [errorDetails, setErrorDetails] = useState<string | null>(null);
  const [pdfFile, setPdfFile] = useState<File | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<SLIFormData>({
    resolver: zodResolver(sliSchema)
  });

  const handleFileChange = (file: File | null) => {
    setPdfFile(file);
    setError(null);
    setErrorDetails(null);
  };

  const handlePDFSubmit = async () => {
    if (!pdfFile) {
      setError('Please select a PDF file');
      return;
    }

    setIsSubmitting(true);
    setError(null);
    setErrorDetails(null);

    try {
      // Create a URL for the file
      const fileUrl = URL.createObjectURL(pdfFile);
      const fileName = `SLI_${shipment.referenceNumber}_${Date.now()}.pdf`;

      await updateShipment(shipment.id, {
        ...shipment,
        documents: {
          ...shipment.documents,
          sli: {
            url: fileUrl,
            fileName,
            uploadDate: new Date().toISOString()
          }
        },
        sliSubmitted: true,
        sliSubmittedAt: new Date().toISOString(),
        status: ShipmentStatus.WAITING_FOR_LOADING
      });

      onSuccess?.();
      onClose();
    } catch (error) {
      console.error('Error uploading SLI:', error);
      if (error instanceof PDFParseError) {
        setError(error.message);
        setErrorDetails(error.details);
      } else {
        setError('Failed to upload SLI document');
        setErrorDetails('Please try again or contact support if the problem persists');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmit = async (data: SLIFormData) => {
    setIsSubmitting(true);
    setError(null);

    try {
      await updateShipment(shipment.id, {
        ...shipment,
        sliData: data,
        sliSubmitted: true,
        sliSubmittedAt: new Date().toISOString(),
        status: ShipmentStatus.WAITING_FOR_LOADING
      });

      onSuccess?.();
      onClose();
    } catch (error: any) {
      console.error('Error submitting SLI:', error);
      setError(error.message || 'Failed to submit SLI');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6 border-b border-gray-200 flex justify-between items-center sticky top-0 bg-white z-10">
          <h2 className="text-xl font-bold text-gray-900">Submit SLI</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="p-6">
          <BookingInfoBanner
            bookingNumber={shipment.bookingNumber}
            mblNumber={shipment.mblNumber}
            vessel={shipment.vessel}
            etd={shipment.etd}
            eta={shipment.eta}
            portOfLoading={shipment.shippingDetails?.portOfLoading}
            portOfDischarge={shipment.shippingDetails?.portOfDischarge}
            containerType={shipment.shipmentInfo?.containerType}
            containerQuantity={shipment.shipmentInfo?.containerQuantity}
            shippingLine={shipment.shippingDetails?.shippingLine}
          />

          <div className="mt-6">
            <PDFUploader
              pdfFile={pdfFile}
              onFileChange={handleFileChange}
              error={error}
              errorDetails={errorDetails}
              isSubmitting={isSubmitting}
              onSubmit={handlePDFSubmit}
              onCancel={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SLIConfirmationForm;