import React from 'react';
import { useShipmentStore } from '../stores/shipmentStore';
import { ShipmentStatus } from '../types/shipment';
import { Ship, Package, MapPin } from 'lucide-react';
import { format, isValid, parseISO } from 'date-fns';

const ShipmentTracker = () => {
  const { shipments } = useShipmentStore();

  // Filter active shipments
  const activeShipments = shipments.filter(s => 
    s.status === ShipmentStatus.DEPARTED && 
    s.departureDate && 
    s.estimatedArrival
  );

  const formatDate = (dateString: string | undefined | null) => {
    if (!dateString) return 'Not set';
    try {
      const date = parseISO(dateString);
      return isValid(date) ? format(date, 'MMM d, yyyy') : 'Invalid date';
    } catch {
      return 'Invalid date';
    }
  };

  if (activeShipments.length === 0) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-lg font-semibold mb-4">Active Shipments Overview</h2>
        <div className="text-center text-gray-500 py-8">
          No active shipments at the moment
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-lg font-semibold mb-4">Active Shipments Overview</h2>
      
      <div className="space-y-6">
        {/* Shipment Timeline */}
        <div className="relative">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="h-0.5 w-full bg-gray-200"></div>
          </div>
          <div className="relative flex justify-between">
            {activeShipments.map((shipment) => (
              <div key={shipment.id} className="flex flex-col items-center">
                <div className="relative flex h-8 w-8 items-center justify-center rounded-full bg-blue-600 hover:bg-blue-700 cursor-pointer group">
                  <Ship className="h-5 w-5 text-white" />
                  
                  {/* Tooltip */}
                  <div className="absolute bottom-full mb-2 hidden group-hover:block w-48 bg-gray-900 text-white text-xs rounded p-2">
                    <p>Vessel: {shipment.vessel || 'Not set'}</p>
                    <p>From: {shipment.shippingDetails?.portOfLoading || 'Not set'}</p>
                    <p>To: {shipment.shippingDetails?.portOfDischarge || 'Not set'}</p>
                    <p>ETA: {formatDate(shipment.estimatedArrival)}</p>
                  </div>
                </div>
                <div className="mt-2 text-xs text-gray-500">
                  {shipment.referenceNumber}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Shipment Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
          {activeShipments.map(shipment => (
            <div key={shipment.id} className="border rounded-lg p-4 hover:bg-blue-50 transition-colors">
              <div className="flex items-start justify-between">
                <div>
                  <div className="flex items-center space-x-2">
                    <Ship className="h-5 w-5 text-blue-600" />
                    <h3 className="font-medium">{shipment.vessel || 'Vessel TBD'}</h3>
                  </div>
                  <div className="mt-2 space-y-1 text-sm text-gray-600">
                    <div className="flex items-center space-x-2">
                      <Package className="h-4 w-4" />
                      <span>Reference #: {shipment.referenceNumber}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <MapPin className="h-4 w-4" />
                      <span>From: {shipment.shippingDetails?.portOfLoading || 'Not set'}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <MapPin className="h-4 w-4" />
                      <span>To: {shipment.shippingDetails?.portOfDischarge || 'Not set'}</span>
                    </div>
                  </div>
                </div>
                <div className="text-right">
                  <div className="text-sm font-medium">ETA</div>
                  <div className="text-sm text-gray-500">
                    {formatDate(shipment.estimatedArrival)}
                  </div>
                </div>
              </div>

              {/* Progress Bar */}
              <div className="mt-4">
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div 
                    className="bg-blue-600 h-2.5 rounded-full" 
                    style={{ 
                      width: shipment.status === ShipmentStatus.DEPARTED ? '75%' : '50%'
                    }}
                  ></div>
                </div>
                <div className="flex justify-between mt-1 text-xs text-gray-500">
                  <span>{shipment.shippingDetails?.portOfLoading || 'Origin'}</span>
                  <span>In Transit</span>
                  <span>{shipment.shippingDetails?.portOfDischarge || 'Destination'}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShipmentTracker;